import axios from 'axios';

const headers = {
  Authorization: 'Token afea7c23ad1cbb859856cfc1fa82e75af247acc9',
};

export const listMessagesService = async () => {
  const base_url = 'http://localhost:3001';
  const response = await axios.get(`${base_url}/orgMessage/`, {
    headers,
  });

  return response.data;
};