export const LIST_ORG_USERS = 'LIST_ORG_USERS';
export const LIST_ORG_USERS_SUCCESS = 'LIST_ORG_USERS_SUCCESS';
export const LIST_ORG_USERS_FAILED = 'LIST_ORG_USERS_FAILED';

export const CREATE_ORG_USER = 'CREATE_ORG_USER';
export const CREATE_ORG_USER_SUCCESS = 'CREATE_ORG_USER_SUCCESS';
export const CREATE_ORG_USER_FAIL = 'CREATE_ORG_USER_FAIL';

export const EDIT_ORG_USER = 'EDIT_ORG_USER';
export const EDIT_ORG_USER_SUCCESS = 'EDIT_ORG_USER_SUCCESS';
export const EDIT_ORG_USER_FAIL = 'EDIT_ORG_USER_FAIL';

export const VIEW_ORG_USER = 'VIEW_ORG_USER';
export const VIEW_ORG_USER_SUCCESS = 'VIEW_ORG_USER_SUCCESS';
export const VIEW_ORG_USER_FAIL = 'VIEW_ORG_USER_FAIL';

export const CHANGE_ORG_USER_STATUS = 'CHANGE_ORG_USER_STATUS';
export const CHANGE_ORG_USER_STATUS_SUCCESS = 'CHANGE_ORG_USER_STATUS_SUCCESS';
export const CHANGE_ORG_USER_STATUS_FAIL = 'CHANGE_ORG_USER_STATUS_FAIL';

export const UPDATE_ORG_USER_PASSWORD = 'UPDATE_ORG_USER_PASSWORD';
export const UPDATE_ORG_USER_PASSWORD_SUCCESS = 'UPDATE_ORG_USER_PASSWORD_SUCCESS';
export const UPDATE_ORG_USER_PASSWORD_FAIL = 'UPDATE_ORG_USER_PASSWORD_FAIL';
