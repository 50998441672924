import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isEmpty } from '../utils/generic';
import Dashboard from '../pages/dashboard/index';
import CustomersList from '../pages/customers';
import CustomerDetails from '../pages/customers/customer-details';
import OrgDetails from '../pages/org-details';
import CreateNewMessage from '../pages/org-message/create-new-message';
import OrgMessages from '../pages/org-message';
import MessageDetails from '../pages/org-message/message-details';
import EditOrgMessage from '../pages/org-message/EditOrgMessage';
import Login from '../pages/login';
import EditProfile from '../pages/edit-profile';
import OrgUsers from '../pages/org-users';
import EditOrg from '../pages/org-details/edit-org';
import SystemUserDetails from '../pages/org-users/user-details';

const PrivateRoutes = ({ auth }) => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          !isEmpty(auth.currentUser) ? <Redirect to="/dashboard" /> : <Login />
        }
      />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/customers" component={CustomersList} />
      <Route path="/customers/:subscriber_id" component={CustomerDetails} />
      <Route path="/messages/new" component={CreateNewMessage} />
      <Route exact path="/messages" component={OrgMessages} />
      <Route exact path="/messages/:message_uuid" component={MessageDetails} />
      <Route
        exact
        path="/messages/edit/:message_uuid"
        component={EditOrgMessage}
      />
      <Route exact path="/org-details" component={OrgDetails} />
      <Route
        exact
        path="/org-details/edit/:organization_uuid"
        component={EditOrg}
      />
      <Route exact path="/users" component={OrgUsers} />
      <Route exact path="/users" component={OrgDetails} />
      <Route exact path="/users/:profile_uuid" component={SystemUserDetails} />
      <Route exact path="/edit-profile/:uuid" component={EditProfile} />
    </Switch>
  );
};

export default PrivateRoutes;
