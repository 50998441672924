import React, { useState, useEffect } from 'react';
import { Select, Skeleton, Empty, Spin } from 'antd';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Section from '../../components/dashboard/section';
import { Column } from '@ant-design/charts';
import { formatNumber } from '../../utils/numbers';
import { useTranslation } from 'react-i18next';
import WidgetTitle from '../../components/dashboard/widget-title';
import { viewOrganizationAction } from '../../store/actions/organizationActions';
import { getDashboardStats } from '../../store/actions/dashboardActions';
import {
	BiCheckCircle,
	BiXCircle,
	BiPaperPlane,
	BiTime,
	BiNote,
} from 'react-icons/bi';
import { FiUsers, FiMail } from 'react-icons/fi';
import { AiOutlineUserAdd, AiOutlineUserDelete } from 'react-icons/ai';
import moment from 'moment';
import { isEmpty } from '../../utils/generic';
import { DashboardDetails, BarChartSkeleton, WidgetSkeleton } from '../../components/skeleton'

const Index = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { organization, auth, dashboard } = useSelector(
		(state) => ({
			organization: state.organization,
			auth: state.auth,
			dashboard: state.dashboard,
		}),
		shallowEqual
	);

	const { currentOrg, currentOrgLoading } = organization;
	const { currentUser } = auth;
	const { stats, statsLoading } = dashboard;
	const organization_uuid = currentUser?.organization;

	useEffect(() => {
		dispatch(viewOrganizationAction(organization_uuid));
		dispatch(getDashboardStats(organization_uuid));
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	const active_customers_by_month = stats?.subscribed_customers_by_month?.map(
		(item) => {
			return {
				name: 'Subscribed Customers',
				month: moment(item.sub_date).format('MMM YY'),
				count: item.customer_count,
			};
		}
	);

	const inactive_customers_by_month =
		stats?.unsubscribed_customers_by_month?.map((item) => {
			return {
				name: 'Unsubscribed Customers',
				month: moment(item.sub_date).format('MMM YY'),
				count: item.customer_count,
			};
		});

	const all_messages_by_month = stats?.messages_by_month.map((item) => {
		return {
			name: 'All Messages',
			month: moment(item.date).format('MMM YY'),
			total: item.all_message_count,
		};
	});

	const published_messages_by_month = stats?.messages_by_month.map((item) => {
		return {
			name: 'Published Messages',
			month: moment(item.date).format('MMM YY'),
			total: item.published_message_count,
		};
	});

	const scheduled_messages_by_month = stats?.messages_by_month.map((item) => {
		return {
			name: 'Scheduled Messages',
			month: moment(item.date).format('MMM YY'),
			total: item.scheduled_message_count,
		};
	});

	const draft_messages_by_month = stats?.messages_by_month.map((item) => {
		return {
			name: 'Draft Messages',
			month: moment(item.date).format('MMM YY'),
			total: item.draft_message_count,
		};
	});

	const [orgChartData, setOrgChartData] = useState('Subscribed');
	const [orgMessageCountData, setOrgMessageCountData] = useState(all_messages_by_month);

	const [chartTitle, setChartTitle] = useState(
		'dashboard.customer_details.graph_title_subscribed'
	);

	const [messageChartTitle, setMessageChartTitle] = useState(
		'dashboard.message_details.graph_title_all'
	);

	const { Option } = Select;

	const handleOrgChart = (value) => {
		if (value === 'Subscribed') {
			setOrgChartData('Subscribed');
			setChartTitle('dashboard.customer_details.graph_title_subscribed');
		} else {
			setOrgChartData('unsubscribed');
			setChartTitle('dashboard.customer_details.graph_title_unsubscribed');
		}
	};

	const handleOrgMessageChart = (value) => {
		switch (value) {
			case 'all':
				// setOrgMessageChartData('all');
				setMessageChartTitle('dashboard.message_details.graph_title_all');
				setOrgMessageCountData(all_messages_by_month);
				break;

			case 'published':
				// setOrgMessageChartData('published');
				setMessageChartTitle('dashboard.message_details.graph_title_published');
				setOrgMessageCountData(published_messages_by_month);
				break;

			case 'scheduled':
				// setOrgMessageChartData('scheduled');
				setMessageChartTitle('dashboard.message_details.graph_title_scheduled');
				setOrgMessageCountData(scheduled_messages_by_month);
				break;

			case 'draft':
				// setOrgMessageChartData('draft');
				setMessageChartTitle('dashboard.message_details.graph_title_draft');
				setOrgMessageCountData(draft_messages_by_month);
				break;

			default:
				break;
		}
	};

	const orgChartConfig = {
		data:
			orgChartData === 'Subscribed'
				? active_customers_by_month
				: inactive_customers_by_month,
		xField: 'month',
		yField: 'count',
		color: '#22C55E',
		legend: false,
		height: 230,
	};

	const orgMessageConfig = {
		data: orgMessageCountData || all_messages_by_month,
		xField: 'month',
		yField: 'total',
		color: '#007AFF',
		legend: false,
		height: 340,
	};

	return (
		<>
			{/* Org Details */}
			<div className="p-4 rounded-lg bg-white border border-grey-100 mb-6">
				{currentOrgLoading ? (
					<>
						<DashboardDetails />
					</>
				) : (
					<>
						<div className="w-full border-b border-grey-100 pb-4 mb-4">
							<div className="flex items-center h-48 w-full lg:w-1/4 p-4 border-gray-400 border">
								<img src={currentOrg?.logo} alt="" className="h-auto w-full" />
							</div>
						</div>

						<div className="w-full">
							<div className="text-lg font-bold mb-4">{currentOrg?.name}</div>
							<div className="mb-4">
								<div className="text-sm text-grey-700 mb-3">
									{t('dashboard.orgDetails.category')}
								</div>
								<div className="text-base font-bold">{currentOrg?.category}</div>
							</div>
							<div>
								<div className="text-sm text-grey-700 mb-3">
									{t('dashboard.orgDetails.status')}
								</div>
								<div className="text-base font-bold flex items-center">
									<span className="mr-2">
										{currentOrg?.is_active ? (
											<BiCheckCircle size={24} className="text-green-anak" />
										) : (
											<BiXCircle size={24} className="text-red-anak" />
										)}
									</span>
									<span>
										{currentOrg?.is_active ? (
											<span className="text-green-anak"> Active </span>
										) : (
											<span className="text-red-anak"> Inactive </span>
										)}
									</span>
								</div>
							</div>
						</div>
					</>
				)}

			</div>

			{/* Customer Details */}

			{statsLoading ? (
				<>
					<WidgetTitle title={t('dashboard.customer_details.section_title')} />
					<div className="mb-6 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
						<div className="w-full lg:w-1/3 mb-6 lg:mb-0">
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 lg:gap-4 mb-4 lg:mb-0">
								{/* Total Customers  */}
								<div className="w-full px-4 pb-2 pt-2 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<WidgetSkeleton />
								</div>

								{/* Subscribed Customers */}
								<div className="w-full px-4 pb-2 pt-2 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<WidgetSkeleton />
								</div>

								{/* Unsubscribed Customers */}
								<div className="w-full px-4 pb-2 pt-2 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<WidgetSkeleton />
								</div>
							</div>
						</div>
						<div className="w-full lg:w-2/3">
							<div className="rounded-lg bg-white border border-grey-100 p-4 pb-3">
								<BarChartSkeleton />
							</div>
						</div>
					</div>
					<WidgetTitle title={t('dashboard.message_details.section_title')} />
					<div className="mb-6 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
						<div className="w-full lg:w-2/3">
							<div className="rounded-lg bg-white border border-grey-100 px-4 pt-16 pb-3">
								<BarChartSkeleton />
							</div>
						</div>
						<div className="w-full lg:w-1/3 mb-6 lg:mb-0">
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 lg:gap-4 mb-3 lg:mb-0">
								<div className="w-full px-4 py-0 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<WidgetSkeleton />
								</div>

								<div className="w-full px-4 py-0 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<WidgetSkeleton />
								</div>

								<div className="w-full px-4 py-0 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<WidgetSkeleton />
								</div>

								<div className="w-full px-4 py-0 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<WidgetSkeleton />
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<WidgetTitle title={t('dashboard.customer_details.section_title')} />
					<div className="mb-6 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
						<div className="w-full lg:w-1/3 mb-6 lg:mb-0">
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 lg:gap-4 mb-4 lg:mb-0">
								{/* Total Customers  */}
								<div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-3">
										<FiUsers
											size={32}
											className="stroke-current text-blue-anak"
										/>
									</div>
									<div className="ml-4">
										<div className="text-xs font-medium truncate">
											{t('dashboard.customer_details.total_customers')}
										</div>
										<div className="flex items-center justify-between">
											<div className="text-3xl font-bold">
												{formatNumber(
													stats?.customer_overview?.total_customers
												)}
											</div>
										</div>
									</div>
								</div>

								{/* Subscribed Customers */}
								<div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-3">
										<AiOutlineUserAdd
											size={32}
											className="stroke-current text-blue-anak"
										/>
									</div>
									<div className="ml-4">
										<div className="text-xs font-medium truncate">
											{t('dashboard.customer_details.subscribed_customers')}
										</div>
										<div className="flex items-center justify-between">
											<div className="text-3xl font-bold">
												{formatNumber(
													stats?.customer_overview?.subscribed_customers
												)}
											</div>
										</div>
									</div>
								</div>

								{/* Unsubscribed Customers */}
								<div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-3">
										<AiOutlineUserDelete
											size={32}
											className="stroke-current text-blue-anak"
										/>
									</div>
									<div className="ml-4">
										<div className="text-xs font-medium truncate">
											{t('dashboard.customer_details.unsubscribed_customers')}
										</div>
										<div className="flex items-center justify-between">
											<div className="text-3xl font-bold">
												{formatNumber(
													stats?.customer_overview?.unsubscribed_customers
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="w-full lg:w-2/3">
							<Section
								title={t(chartTitle)}
								right={
									<Select
										defaultValue="Subscribed"
										style={{ width: 210 }}
										onChange={handleOrgChart}
									>
										<Option value="Subscribed">
											{t('dashboard.customer_details.subscribed_customers')}
										</Option>
										<Option value="Unsubscribed">
											{t('dashboard.customer_details.unsubscribed_customers')}
										</Option>
									</Select>
								}
							>
								<div className="flex flex-row w-full">
									<div style={{ width: '100%' }}>
										{isEmpty(orgChartConfig.data) ? (
											<Empty
												className="mt-4 lg:mt-6 flex justify-center flex-col"
												style={{ minHeight: 206 }}
											/>
										) : (
											<Column {...orgChartConfig} />
										)}
									</div>
								</div>
							</Section>
						</div>
					</div>
					<WidgetTitle title={t('dashboard.message_details.section_title')} />
					<div className="mb-6 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
						<div className="w-full lg:w-2/3">
							<Section
								title={t(messageChartTitle)}
								right={
									<Select
										defaultValue="all"
										style={{ width: 200 }}
										onChange={handleOrgMessageChart}
									>
										<Option value="all">
											{t('dashboard.message_details.all_msgs')}
										</Option>
										<Option value="published">
											{t('dashboard.message_details.published_msgs')}
										</Option>
										<Option value="scheduled">
											{t('dashboard.message_details.scheduled_msgs')}
										</Option>
										<Option value="draft">
											{t('dashboard.message_details.draft_msgs')}
										</Option>
									</Select>
								}
							>
								<div className="flex flex-row w-full">
									<div style={{ width: '100%' }}>
										{isEmpty(orgMessageConfig.data) ? (
											<Empty
												className="mt-4 lg:mt-6 flex justify-center flex-col"
												style={{ minHeight: 318 }}
											/>
										) : (
											<Column {...orgMessageConfig} />
										)}
									</div>
								</div>
							</Section>
						</div>

						<div className="w-full lg:w-1/3 mb-6 lg:mb-0">
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 lg:gap-4 mb-4 lg:mb-0">
								{/* Total Customers  */}
								<div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-3">
										<FiMail
											size={32}
											className="stroke-current text-blue-anak"
										/>
									</div>
									<div className="ml-4">
										<div className="text-xs font-medium truncate">
											{t('dashboard.message_details.all_msgs')}
										</div>
										<div className="flex items-center justify-between">
											<div className="text-3xl font-bold">
												{formatNumber(stats?.messages_overview?.total_messages)}
											</div>
										</div>
									</div>
								</div>

								{/* Subscribed Customers */}
								<div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-3">
										<BiPaperPlane
											size={32}
											className="stroke-current text-blue-anak"
										/>
									</div>
									<div className="ml-4">
										<div className="text-xs font-medium truncate">
											{t('dashboard.message_details.published_msgs')}
										</div>
										<div className="flex items-center justify-between">
											<div className="text-3xl font-bold">
												{formatNumber(
													stats?.messages_overview?.published_messages
												)}
											</div>
										</div>
									</div>
								</div>

								{/* Subscribed Customers */}
								<div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-3">
										<BiTime
											size={32}
											className="stroke-current text-blue-anak"
										/>
									</div>
									<div className="ml-4">
										<div className="text-xs font-medium truncate">
											{t('dashboard.message_details.scheduled_msgs')}
										</div>
										<div className="flex items-center justify-between">
											<div className="text-3xl font-bold">
												{formatNumber(
													stats?.messages_overview?.scheduled_messages
												)}
											</div>
										</div>
									</div>
								</div>

								{/* Subscribed Customers */}
								<div className="w-full px-4 pb-3 pt-4 rounded-lg bg-white border border-grey-100 flex flex-row items-center">
									<div className="bg-blue-100 bg-opacity-25 border border-blue-100 block rounded-full p-3">
										<BiNote
											size={32}
											className="stroke-current text-blue-anak"
										/>
									</div>
									<div className="ml-4">
										<div className="text-xs font-medium truncate">
											{t('dashboard.message_details.draft_msgs')}
										</div>
										<div className="flex items-center justify-between">
											<div className="text-3xl font-bold">
												{formatNumber(stats?.messages_overview?.draft_messages)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}


		</>
	);
};
export default Index;
