export const LIST_ORG_CATEGORIES = 'LIST_ORG_CATEGORIES';
export const LIST_ORG_CATEGORIES_SUCCESS = 'LIST_ORG_CATEGORIES_SUCCESS';
export const LIST_ORG_CATEGORIES_FAILED = 'LIST_ORG_CATEGORIES_FAILED';

export const CREATE_ORG_CATEGORY = 'CREATE_ORG_CATEGORY';
export const CREATE_ORG_CATEGORY_SUCCESS = 'CREATE_ORG_CATEGORY_SUCCESS';
export const CREATE_ORG_CATEGORY_FAIL = 'CREATE_ORG_CATEGORY_FAIL';

export const EDIT_ORG_CATEGORY = 'EDIT_ORG_CATEGORY';
export const EDIT_ORG_CATEGORY_SUCCESS = 'EDIT_ORG_CATEGORY_SUCCESS';
export const EDIT_ORG_CATEGORY_FAIL = 'EDIT_ORG_CATEGORY_FAIL';

export const VIEW_ORG_CATEGORY = 'VIEW_ORG_CATEGORY';
export const VIEW_ORG_CATEGORY_SUCCESS = 'VIEW_ORG_CATEGORY_SUCCESS';
export const VIEW_ORG_CATEGORY_FAIL = 'VIEW_ORG_CATEGORY_FAIL';

export const DELETE_ORG_CATEGORY = 'DELETE_ORG_CATEGORY';
export const DELETE_ORG_CATEGORY_SUCCESS = 'DELETE_ORG_CATEGORY_SUCCESS';
export const DELETE_ORG_CATEGORY_FAIL = 'DELETE_ORG_CATEGORY_FAIL';
