import * as types from '../actionTypes/org-category';

// ORG CATEGORIES LISTING
export const listOrgCategoryAction = (payload) => {
  return {
    type: types.LIST_ORG_CATEGORIES,
    payload,
  };
};
export const listOrgCategorySuccessAction = (categories) => {
  return {
    type: types.LIST_ORG_CATEGORIES_SUCCESS,
    payload: categories,
  };
};
export const listOrgCategoryFailAction = (error) => {
  return {
    type: types.LIST_ORG_CATEGORIES_FAILED,
    payload: error,
  };
};

// CREATE ORG CATEGORY
export const createOrgCategoryAction = (values) => {
  return {
    type: types.CREATE_ORG_CATEGORY,
    payload: values,
  };
};
export const createOrgCategorySuccessAction = (category) => {
  return {
    type: types.CREATE_ORG_CATEGORY_SUCCESS,
    payload: category,
  };
};
export const createOrgCategoryFailAction = (error) => {
  return {
    type: types.CREATE_ORG_CATEGORY_FAIL,
    payload: error,
  };
};

// VIEW ORG CATEGORY
export const viewOrgCategoryAction = (OrgCategoryUuid) => {
  return {
    type: types.VIEW_ORG_CATEGORY,
    payload: OrgCategoryUuid,
  };
};
export const viewOrgCategorySuccessAction = (category) => {
  return {
    type: types.VIEW_ORG_CATEGORY_SUCCESS,
    payload: category,
  };
};
export const viewOrgCategoryFailAction = (error) => {
  return {
    type: types.VIEW_ORG_CATEGORY_FAIL,
    payload: error,
  };
};

// EDIT ORG CATEGORY
export const editOrgCategoryAction = (values) => {
  return {
    type: types.EDIT_ORG_CATEGORY,
    payload: values,
  };
};
export const editOrgCategorySuccessAction = (updatedCategory) => {
  return {
    type: types.EDIT_ORG_CATEGORY_SUCCESS,
    payload: updatedCategory,
  };
};
export const editOrgCategoryFailAction = (error) => {
  return {
    type: types.EDIT_ORG_CATEGORY_FAIL,
    payload: error,
  };
};

// DELETE ORG CATEGORY
export const deleteOrgCategoryAction = (category_uuid) => {
  return {
    type: types.DELETE_ORG_CATEGORY,
    payload: category_uuid,
  };
};
export const deleteOrgCategorySuccessAction = () => {
  return {
    type: types.DELETE_ORG_CATEGORY_SUCCESS,
  };
};
export const deleteOrgCategoryFailAction = (error) => {
  return {
    type: types.DELETE_ORG_CATEGORY_FAIL,
    payload: error,
  };
};
