import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import { DefaultTabs } from '../../components/tabs';
import TabDescription from '../../components/tabs/tab-description';
import TabContact from '../../components/tabs/tab-contact';
import { Button, Skeleton } from 'antd';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import { viewOrganizationAction } from '../../store/actions/organizationActions';
import { useTranslation } from 'react-i18next';
import OrgWelcomeMessage from '../../components/tabs/welcome-message';

function OrgDetails() {
  const { t } = useTranslation();

  let title = t('org_details.section_title');
  let breadcrumbsItem = [
    { title, url: '', last: true },
  ];

  const dispatch = useDispatch();

  const { organization, auth } = useSelector(
    (state) => ({
      organization: state.organization,
      auth: state.auth,
    })
  );

  const { currentOrg, currentOrgLoading } = organization;

  const organization_uuid = auth?.currentUser?.organization;

  useEffect(() => {
    dispatch(viewOrganizationAction(organization_uuid));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const tabs = [
    {
      index: 0,
      title: t('org_details.tab_description'),
      active: true,
      content: <TabDescription body={currentOrg?.description} />,
    },
    {
      index: 1,
      title: t('org_details.tab_contact'),
      active: false,
      content: <TabContact email={currentOrg?.email} phone={currentOrg?.phone} website={currentOrg?.website} />,
    },
    {
      index: 5,
      title: 'Welcome Message',
      active: false,
      content: <OrgWelcomeMessage />,
    },
  ];

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="w-full flex flex-col lg:flex-row lg:space-x-4">
        <div className="w-full lg:w-1/3 bg-white min-h-48 border border-grey-200 rounded-sm p-4 flex justify-center items-center">
          {currentOrgLoading ?
            (<Skeleton.Image active />) :
            (<img src={currentOrg?.logo} alt="" />)
          }
        </div>
        <div className="w-full">
          <div className="font-bold text-xl pr-4 mt-3 mb-4 border-b border-grey-200 pb-3">
            {currentOrgLoading ? (
              <Skeleton.Input style={{ width: 200 }} active />
            ) : (
              <div className="text-lg font-bold">{currentOrg?.name}</div>
            )}
          </div>
          <div className="mt-3 mb-4 border-b border-grey-200 pb-3">
            <div className="text-md mb-2">{t('org_details.status')}</div>
            <div className="flex items-center text-lg font-bold">
              <span className="mr-2">
                {
                  currentOrg?.is_active ?
                    (<BiCheckCircle size={24} className="text-green-anak" />) :
                    (<BiXCircle size={24} className="text-red-anak" />)
                }
              </span>
              <span className={currentOrg?.is_active ? 'text-green-anak' : 'text-red-anak'}>{currentOrg?.is_active ? 'Active' : 'Inactive'}</span>
            </div>
          </div>
          <div className="mt-3 mb-4 border-b border-grey-200 pb-4">
            <div className="text-md mb-2"> {t('org_details.category')} </div>
            <div className="flex items-center text-lg font-bold">
              {currentOrgLoading ? (
                <Skeleton.Input style={{ width: 200 }} active />
              ) : (
                <div className="text-lg font-bold">{currentOrg?.category}</div>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row my-4">
            <Link to={`/org-details/edit/${organization_uuid}`}>
              <Button
                type="success"
                size="large"
                className="mr-0 w-full mb-4 lg:mb-0 lg:mr-4"
              >
                {t('org_details.edit_modal.button_text')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="my-6">
        <DefaultTabs tabs={tabs} />
      </div>
    </>
  );
}

export default OrgDetails;
