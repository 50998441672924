import * as types from '../actionTypes/org-category';

const initialState = {
  orgCategoriesList: null,
  orgCategoriesListLoading: false,
  orgCategoriesListError: null,
  currentCategory: null,
  currentCategoryLoading: false,
  currentCategoryError: null,
  createCategoryLoading: false,
  createCategoryError: null,
};

const orgCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_ORG_CATEGORIES:
      return {
        ...state,
        orgCategoriesListLoading: true,
      };
    case types.LIST_ORG_CATEGORIES_SUCCESS:
      return {
        ...state,
        orgCategoriesList: action.payload,
        orgCategoriesListLoading: false,
      };
    case types.LIST_ORG_CATEGORIES_FAILED:
      return {
        ...state,
        orgCategoriesListLoading: false,
        orgCategoriesListError: action.payload,
      };
    case types.VIEW_ORG_CATEGORY:
      return {
        ...state,
        currentCategoryLoading: true,
      };
    case types.VIEW_ORG_CATEGORY_SUCCESS:
      return {
        ...state,
        currentCategory: action.payload,
        currentCategoryLoading: false,
      };
    case types.VIEW_ORG_CATEGORY_FAIL:
      return {
        ...state,
        currentCategoryLoading: false,
        currentCategoryError: action.payload,
      };
    case types.CREATE_ORG_CATEGORY:
      return {
        ...state,
        createCategoryLoading: true,
      };
    case types.CREATE_ORG_CATEGORY_SUCCESS:
      return {
        ...state,
        createCategoryLoading: false,
      };

    case types.CREATE_ORG_CATEGORY_FAIL:
      return {
        createCategoryError: action.payload,
      };
    case types.EDIT_ORG_CATEGORY:
      return {
        ...state,
        currentCategoryLoading: true,
      };
    case types.EDIT_ORG_CATEGORY_SUCCESS:
      return {
        ...state,
        currentCategory: action.payload,
        currentCategoryLoading: false,
      };
    case types.EDIT_ORG_CATEGORY_FAIL:
      return {
        ...state,
        currentCategoryLoading: false,
        currentCategoryError: action.payload,
      };
    default:
      return state;
  }
};

export default orgCategoryReducer;
