import React from 'react'
import ContentLoader from 'react-content-loader'

export function BarChartSkeleton(props) {
	return (
		<ContentLoader
			speed={1}
			width={712}
			height={320}
			viewBox="0 0 712 320"
			backgroundColor="#dedede"
			foregroundColor="#c4c4c4"
			{...props}
		>
			<rect x="6" y="137" rx="0" ry="0" width="40" height="266" />
			<rect x="381" y="416" rx="0" ry="0" width="1" height="3" />
			<rect x="113" y="312" rx="0" ry="0" width="0" height="1" />
			<rect x="68" y="79" rx="0" ry="0" width="40" height="327" />
			<rect x="129" y="113" rx="0" ry="0" width="40" height="293" />
			<rect x="186" y="189" rx="0" ry="0" width="40" height="217" />
			<rect x="248" y="133" rx="0" ry="0" width="40" height="273" />
			<rect x="311" y="139" rx="0" ry="0" width="40" height="266" />
			<rect x="372" y="80" rx="0" ry="0" width="40" height="327" />
			<rect x="432" y="115" rx="0" ry="0" width="40" height="293" />
			<rect x="490" y="190" rx="0" ry="0" width="40" height="217" />
			<rect x="552" y="135" rx="0" ry="0" width="40" height="273" />
			<rect x="612" y="115" rx="0" ry="0" width="40" height="293" />
			<rect x="670" y="190" rx="0" ry="0" width="40" height="217" />
			<rect x="732" y="135" rx="0" ry="0" width="40" height="273" />
		</ContentLoader>
	)
}

export function WidgetSkeleton(props) {
	return (
		<ContentLoader
			speed={1}
			width={145}
			height={86}
			viewBox="0 0 145 86"
			backgroundColor="#dedede"
			foregroundColor="#c2c2c2"
			{...props}
		>
			<rect x="10" y="20" rx="3" ry="3" width="125" height="14" />
			<rect x="10" y="50" rx="3" ry="3" width="90" height="12" />
			<rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
		</ContentLoader>
	)
}

export function DashboardDetails(props) {
	return (
		<ContentLoader
			speed={1}
			width={275}
			height={399}
			viewBox="0 0 275 399"
			backgroundColor="#dedede"
			foregroundColor="#c2c2c2"
			{...props}
		>
			<rect x="10" y="20" rx="3" ry="3" width="125" height="14" />
			<rect x="10" y="50" rx="3" ry="3" width="90" height="12" />
			<rect x="0" y="88" rx="3" ry="3" width="178" height="6" />

			<rect id="Rectangle" x="0" y="232" width="275" height="19" rx="9.5"></rect>
			<rect id="Rectangle-Copy-2" x="0" y="307" width="239" height="19" rx="9.5"></rect>
			<rect id="Rectangle-Copy-4" x="0" y="380" width="188" height="19" rx="9.5"></rect>
			<rect id="Rectangle-Copy" x="0" y="276" width="95" height="11" rx="5.5"></rect>
			<rect id="Rectangle-Copy-3" x="0" y="349" width="95" height="11" rx="5.5"></rect>
			<rect id="Rectangle" stroke="#979797" x="0.5" y="0.5" width="274" height="192" rx="10"></rect>
		</ContentLoader>
	)
}