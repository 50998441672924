import React, { useState, useEffect, useRef } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import AccountLinks from './account-links';
import { replaceUnderscore } from '../../utils/string-helpers';

const ProfileDropdown = () => {
  const auth = useSelector((state) => state.auth);

  const [hidden, setHidden] = useState(true);

  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const { styles, attributes } = usePopper(
    buttonRef.current,
    dropdownRef.current,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    }
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hidden, dropdownRef, buttonRef]);

  const handleDropdownClick = () => {
    setHidden(!hidden);
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={handleDropdownClick}
        className="flex h-16 rounded-full ml-2 relative items-center space-x-2"
      >
        <span className="flex justify-center items-center w-10 h-10 p-2 border-2 border-white rounded-full bg-white bg-cover bg-center" style={{
          backgroundImage: `url(${auth.currentUser.profile_image})`,
          boxShadow: '0 0px 24px -5px rgba(0, 0, 0, 0.5)'
        }} />
        <span className="text-left">
          <div className="font-bold -mb-1">{auth.currentUser.name}</div>
          <div className=" text-xs capitalize">
            {replaceUnderscore(auth.currentUser.role)}
          </div>
        </span>
        <BiChevronDown className="w-6 h-6 mr-4" />
      </button>
      <div ref={dropdownRef} style={styles.popper} {...attributes.popper}>
        <div
          style={styles.offset}
          className={`dropdown ${hidden ? '' : 'open'}`}
        >
          <div
            onClick={() => setHidden(!hidden)}
            className="dropdown-content w-48 bottom-end"
          >
            {/* {auth.currentUser.id && } */}
            <AccountLinks />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
