export const createdByFormat = (id) => {
  if (id === 1) {
    return 'Gaurav';
  } else if (id === 2) {
    return 'Moderator';
  } else if (id === 3) {
    return 'Org Admin';
  } else if (id === 4) {
    return 'Org User';
  } else {
    return 'NA';
  }
};


export const getRandomColor = () => {
  let letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}