import React, { useEffect } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import { Card, Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import getMessageStatus from '../../components/status/message';
import { viewOrgMessageAction } from '../../store/actions/orgMessageActions';
import dateFormat from '../../utils/dateFormat';
import { useParams } from 'react-router-dom';

function MessageDetails() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { currentMessage, currentMessageLoading } = useSelector(
    (state) => state.orgMessages
  );

  const { message_uuid } = useParams();

  useEffect(() => {
    dispatch(viewOrgMessageAction(message_uuid));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  let title = t('org_message.details.title');
  let breadcrumbsItem = [
    // { home: false, title: t('left_sidebar.dashboard'), url: '/', last: false },
    {
      home: false,
      title: t('org_message.section_title'),
      url: '/messages',
      last: false,
    },
    { title, url: '/', last: true },
  ];

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex flex-col lg:flex-row items-start space-x-0 lg:space-x-4">
        <div className="w-full lg:w-2/3">
          <Card>
            <div className="border-b border-grey-200 pb-4 mb-4">
              <div className="text-md mb-1 text-grey-700">
                {t('org_message.details.name_label')}
              </div>
              {currentMessageLoading ? (
                <Skeleton.Input style={{ width: 200 }} active />
              ) : (
                <div className="text-lg font-bold">
                  {currentMessage?.organization}
                </div>
              )}
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
              <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                <div className="text-md mb-1 text-grey-700">
                  {t('org_message.details.date_label')}
                </div>
                <div className="text-lg font-bold">
                  {dateFormat(currentMessage?.created_at)}
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="text-md mb-1 text-grey-700">
                  {t('org_message.details.language_label')}
                </div>
                <div className="text-lg font-bold">English</div>
              </div>
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4 flex flex-col lg:flex-row space-x-0 lg:space-x-4">
              <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                <div className="text-md mb-1 text-grey-700">
                  {t('org_message.details.status_label')}
                </div>
                {currentMessageLoading ? (
                  <Skeleton.Input style={{ width: 200 }} active />
                ) : (
                  getMessageStatus(currentMessage?.status)
                )}
              </div>
              <div className="w-full lg:w-1/2">
                <div className="text-md mb-1 text-grey-700">
                  {t('org_message.details.views_label')}
                </div>
                {currentMessageLoading ? (
                  <Skeleton.Input style={{ width: 200 }} active />
                ) : (
                  <div className="text-lg font-bold">
                    {currentMessage?.views}
                  </div>
                )}
              </div>
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4">
              <div className="text-md mb-1 text-grey-700">
                {t('org_message.details.heading_label')}
              </div>
              {currentMessageLoading ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : (
                <div className="text-lg font-bold">{currentMessage?.title}</div>
              )}
            </div>

            <div className="border-b border-grey-200 pb-4 mb-4">
              <div className="text-md mb-1 text-grey-700">
                {t('org_message.details.content_label')}
              </div>
              {currentMessageLoading ? (
                <Skeleton active paragraph={{ rows: 6 }} />
              ) : (
                <div
                  className="text-md font-bold"
                  dangerouslySetInnerHTML={{
                    __html: `${currentMessage?.body
                      ? currentMessage?.body
                      : 'Message Body'
                      }`,
                  }}
                ></div>
              )}
            </div>
            <div className="flex flex-col">
              <div className="text-md mb-1 text-grey-700">
                {t('org_message.details.cta_label')}
              </div>
              <div className="text-md font-bold">Make Appointment</div>
            </div>
          </Card>
        </div>

        <div className="w-full lg:w-1/3 flex justify-center">
          <div className="mockup-phone">
            <div className="message">
              {currentMessageLoading ? (
                <Space>
                  <Skeleton.Image className="mr-2 mb-1" />
                  <Skeleton.Input
                    style={{ width: 200 }}
                    active={currentMessageLoading}
                  />
                </Space>
              ) : (
                <div className="message-header">
                  <div className="flex items-center mb-4">
                    {currentMessage?.org_logo ? (
                      <div className="logo">
                        <img src={currentMessage?.org_logo} alt="logo" />
                      </div>
                    ) : (
                      <Skeleton.Image className="mr-2 mb-1" />
                    )}
                    <div className="message-sender">
                      {currentMessageLoading ? (
                        <Skeleton.Input style={{ width: 200 }} active />
                      ) : (
                        <div className="text-md font-bold">
                          {currentMessage?.organization}
                        </div>
                      )}
                      <div className="text-xs text-grey-600">
                        {new Date().toLocaleString() + ''}
                      </div>
                    </div>
                  </div>
                  {currentMessageLoading ? (
                    <Skeleton active paragraph={{ rows: 2 }} />
                  ) : (
                    <div className="message-header-title text-base font-bold leading-5 border-b border-grey-300 pb-4 mb-4">
                      {currentMessage?.title}
                    </div>
                  )}
                </div>
              )}
              {currentMessageLoading ? (
                <Skeleton active paragraph={{ rows: 6 }} />
              ) : (
                <div
                  className="message-content"
                  dangerouslySetInnerHTML={{
                    __html: `${currentMessage?.body
                      ? currentMessage?.body
                      : 'Message Body'
                      }`,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MessageDetails;
