import React from 'react';
import { useSelector } from 'react-redux';

function CheckRole({ children }) {
	const { currentUser } = useSelector((state) => state.auth);
	if (currentUser.role === 'superuser') {
		return <>{children}</>;
	} else return <></>;
}

export default CheckRole;