import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listCustomerAction } from '../../store/actions/customerActions';
import { Link } from 'react-router-dom';
import { BiCheck, BiX } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import dateFormat from '../../utils/dateFormat';

function CustomersTable({ organization_uuid }) {
  const initialTableParams = {
    page: 1,
    search: '',
    is_active: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    organization_uuid,
  };
  const menuFilter = [
    {
      key: 'true',
      value: 'Subscribed Customers',
    },
    {
      key: 'false',
      value: 'Unsubscribed Customers',
    },
  ];
  const { customerList, listCustomerLoading, listCustomerError } = useSelector(
    (state) => state.customers
  );

  const { t } = useTranslation();

  // function randomColor() {
  //   let hex = Math.floor(Math.random() * 0xFFFFFF);
  //   let color = "#" + hex.toString(16);
  //   return color;
  // }

  const { Column } = Table;
  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="is_active"
      dispatchAction={listCustomerAction}
      data={customerList}
      loading={listCustomerLoading}
      error={listCustomerError}
    >
      {/* <Column
        title="Name"
        dataIndex="name"
        key="name"
        // sorter={true}
        align="left"
        render={(text, record) => (
          <div className="flex items-center pl-1">
            {
              record?.profile_image_url ? (
                <span
                  className="flex justify-center items-center md:h-10 w-10 hp-2 border-2 shadow border-white rounded bg-white bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${record?.profile_image_url})`
                  }}
                />
              ) : (
                <Avatar
                  className=" flex justify-center items-center w-24 md:w-48 border-2 border-white font-bold shadow rounded uppercase"
                  style={{ verticalAlign: 'middle', backgroundColor: '#e9e9ea', color: '#323232' }}
                  size={40}
                >
                  {record?.name.charAt(0)}
                </Avatar>
              )
            }
            <div className="ml-3">
              <span className="block text-base font-bold">{record?.name}</span>
              <span className="block text-xs text-grey-600">{record?.mobile}</span>
            </div>
          </div>
        )}
      /> */}
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Phone no."
        dataIndex="mobile"
        key="mobile"
        align="left"
        render={(text, record) => record?.mobile}
      />
      <Column
        title="Subscribed On"
        dataIndex="subscribed_at"
        key="subscribed_at"
        sorter={true}
        render={(text, record) => dateFormat(record?.subscribed_at)}
      />
      <Column
        title="Unsubscribed On"
        dataIndex="unsubscribed_at"
        key="unsubscribed_at"
        // sorter={true}
        render={(text, record) => dateFormat(record?.unsubscribed_at)}
      />
      <Column
        title="Subscription Status"
        dataIndex="is_active"
        key="is_active"
        // sorter={true}
        render={(text, record) => (
          <>
            {record.is_active ?
              (<div className="flex items-center">
                <div className="rounded-full bg-green-anak"><BiCheck className="h-5 w-5 text-white" /></div>
                <span className="text-green-anak font-bold ml-2 capitalize">{t('table.thead.subscribed')}</span>
              </div>) :
              (<div className="flex items-center">
                <div className="rounded-full bg-red-anak"><BiX className="h-5 w-5 text-white " /></div>
                <span className="text-red-anak font-bold ml-2 capitalize">{t('table.thead.unsubscribed')}</span>
              </div>)
            }
          </>
        )}
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/customers/${record.subscriber_id}`}>View Details</Link>
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default CustomersTable;
