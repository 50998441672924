import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '../pages/login';
import ForgotPassword from '../pages/forgot-password';
import ResetPassword from '../pages/reset-password';

function PublicRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />

      <Route path="/forgot-password" component={ForgotPassword} />

      <Route exact path="/reset-password" component={ResetPassword} />
      <Route
        path="/reset-password/?reset_token=:resetToken&email=:email"
        component={ResetPassword}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default PublicRoutes;
