import axiosClient from './config';

export const listCustomerService = async ({
  page,
  search,
  is_active,
  ordering,
  page_size,
  created_at_after: subscribed_at_after,
  created_at_before: subscribed_at_before,
  organization_uuid,
}) => {
  return await axiosClient.get(
    `organisations/${organization_uuid}/subscribers/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&subscribed_at_after=${subscribed_at_after}&subscribed_at_before=${subscribed_at_before}&is_active=${is_active}`
  );
};

export const viewCustomerService = async (organization_uuid, subscriber_id) => {
  return await axiosClient.get(`/organisations/${organization_uuid}/subscribers/${subscriber_id}/`);
};
