import React from 'react';
import {
  BiHomeCircle,
  BiUserCircle,
  BiEnvelope,
  BiUser,
  BiListUl,
} from 'react-icons/bi';

const initialState = [
  {
    items: [
      {
        url: '/dashboard',
        icon: <BiHomeCircle size={20} />,
        title: 'left_sidebar.dashboard',
        items: [],
      },
      {
        url: '/customers',
        icon: <BiUserCircle size={20} />,
        title: 'left_sidebar.customer_list',
        items: [],
      },
      {
        url: '/messages',
        icon: <BiEnvelope size={20} />,
        title: 'left_sidebar.org_message',
        items: [],
      },
      {
        url: '/org-details',
        icon: <BiListUl size={20} />,
        title: 'left_sidebar.org_details',
        items: [],
      },
      {
        url: '/users',
        icon: <BiUser size={20} />,
        title: 'left_sidebar.org_user',
        items: [],
      },
    ],
  },
];

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
