export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const USER_ME_REQUEST = 'USER_ME_REQUEST';
export const USER_ME_SUCCESS = 'USER_ME_SUCCESS';
export const USER_ME_FAIL = 'USER_ME_FAIL';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL';

export const LIST_CUSTOMER = 'LIST_CUSTOMER';
export const LIST_CUSTOMER_SUCCESS = 'LIST_CUSTOMER_SUCCESS';
export const LIST_CUSTOMER_FAILED = 'LIST_CUSTOMER_FAILED';

export const VIEW_CUSTOMER_DETAILS = 'VIEW_CUSTOMER_DETAILS';
export const VIEW_CUSTOMER_DETAILS_SUCCESS = 'VIEW_CUSTOMER_DETAILS_SUCCESS';
export const VIEW_CUSTOMER_DETAILS_FAILED = 'VIEW_CUSTOMER_DETAILS_FAILED';

export const USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED';

export const LIST_ORGUSER = 'LIST_ORGUSER';
export const LIST_ORGUSER_SUCCESS = 'LIST_ORGUSER_SUCCESS';
export const LIST_ORGUSER_FAILED = 'LIST_ORGUSER_FAILED';

export const VIEW_ORGUSER_DETAILS = 'VIEW_ORGUSER_DETAILS';
export const VIEW_ORGUSER_DETAILS_SUCCESS = 'VIEW_ORGUSER_DETAILS_SUCCESS';
export const VIEW_ORGUSER_DETAILS_FAILED = 'VIEW_ORGUSER_DETAILS_FAILED';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const LIST_MESSAGES_REQUEST = 'LIST_MESSAGES_REQUEST';
export const LIST_MESSAGES_SUCCESS = 'LIST_MESSAGES_SUCCESS';
export const LIST_MESSAGES_FAILED = 'LIST_MESSAGES_FAILED';
