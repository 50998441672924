import React, { useEffect } from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import CustomerProfileHeader from '../../components/profile-widgets/customer-profile-header';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { viewCustomerAction } from '../../store/actions/customerActions';
import { Skeleton } from 'antd';

function UserDetails() {
  const { t } = useTranslation();
  let title = t('customer_details.section_title');
  let breadcrumbsItem = [
    // { home: false, title: t('left_sidebar.dashboard'), url: '/', last: false },
    {
      home: false,
      title: t('customer_details.section_title'),
      url: '/customers',
      last: false,
    },
    { title, url: '/', last: true },
  ];

  const { auth, customers } = useSelector(
    (state) => ({
      customers: state.customers,
      auth: state.auth,
    })
  );

  const { currentCustomer, currentCustomerLoading } = customers
  const organization_uuid = auth?.currentUser?.organization;
  const { subscriber_id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewCustomerAction(organization_uuid, subscriber_id));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="w-full mb-4">
        {currentCustomerLoading && (
          <div className="px-6 pb-6 flex space-x-4">
            <div>
              <Skeleton.Avatar active style={{ width: 200, height: 200 }} />
            </div>
            <div className="w-full">
              <Skeleton active paragraph={{ rows: 3 }} />
              <div className="flex justify-between pt-4 space-x-4">
                <Skeleton.Button active block />
                <Skeleton.Button active block />
              </div>
            </div>
          </div>
        )}
        {currentCustomer && (
          <CustomerProfileHeader userDetails={currentCustomer} />
        )}
      </div>
    </>
  );
}

export default UserDetails;
