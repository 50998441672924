import axiosClient from './config';

export const listOrgMessageService = async ({ page, page_size, search, status, ordering, organization_uuid, created_at_before, created_at_after }) => {
  return await axiosClient.get(
    `/organisation-messages/?org_uuid=${organization_uuid}&page=${page}&page_size=${page_size}&search=${search}&status=${status}&ordering=${ordering}&created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};

export const getOrgMessageService = async (message_uuid) => {
  return await axiosClient.get(`/organisation-messages/${message_uuid}/`);
};

export const createOrgMessageService = async (formData) => {
  return await axiosClient.post('/organisation-messages/', formData);
};

export const editOrgMessageService = async ({ message_uuid, values }) => {
  console.log(values);
  return await axiosClient.patch(
    `/organisation-messages/${message_uuid}/`, values
  );
};