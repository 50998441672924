import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import {
  Card,
  Modal,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  Skeleton,
  Space,
  message,
  // Spin,
} from 'antd';
import { BiFile } from 'react-icons/bi';
import ReactQuill from 'react-quill';
import ReactPlayer from 'react-player/youtube';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import {
  // listOrganizationAction,
  viewOrganizationAction,
  viewOrganizationSubscriberAction,
} from '../../store/actions/organizationActions';
import { createOrgMessageAction } from '../../store/actions/orgMessageActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import useDebounce from '../../hooks/useDebounce';
import { isEmpty } from '../../utils/generic';
import { fileUploadHandler, imageUploadHandler } from '../../utils/upload';
import {
  resetFileUploadAction,
  resetImageUploadAction,
} from '../../store/actions/uploadAction';

function CreateNewMessage() {
  const { t } = useTranslation();
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = moment.tz(zone).format('Z');

  let title = t('org_message.create.title');

  let breadcrumbsItem = [
    {
      home: false,
      title: t('org_message.section_title'),
      url: '/organisations/messages',
      last: false,
    },
    {
      home: false,
      title: t('org_message.create.title'),
      url: '/',
      last: true,
    },
  ];

  const { Option } = Select;

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = ['bold', 'italic', 'underline', 'list', 'bullet'];
  const [searchSubscribers, setSearchSubscribers] = useState('');
  const debouncedSearchValue = useDebounce(searchSubscribers, 500);
  // const [searchOrg, setSearchOrg] = useState('');
  // const debouncedOrgSearchValue = useDebounce(searchOrg, 500);

  let orgSubscribersInitialValue = {
    organization_uuid: '',
    page: 1,
    search: '',
    ordering: '',
    page_size: '',
    created_at_after: '',
    created_at_before: '',
  };

  const [scheduleMessageModal, setScheduleMessageModal] = useState(false);
  const [wordLimitWarning, setWordLimitWarning] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  // const [selectedOrganisationUUID, setSelectedOrganisationUUID] = useState(null);
  const [buttonClicked, setButtonClicked] = useState('');

  const [createMessageFormData, setCreateMessageFormData] = useState({
    images: [],
    videos: [],
    files: [],
    recipient: { include: [] },
  });

  const dispatch = useDispatch();

  const { uploadReducer, organization, auth } = useSelector(
    (state) => ({
      uploadReducer: state.uploadReducer,
      organization: state.organization,
      auth: state.auth,
    }),
    shallowEqual
  );

  const { uploadFile, uploadFileLoading, uploadImage, uploadImageLoading } =
    uploadReducer;

  const {
    // orgList,
    // orgListLoading,
    orgSubscribers,
    orgSubscribersLoading,
    currentOrg,
    currentOrgLoading,
  } = organization;

  const selectedOrganisationUUID = auth?.currentUser?.organization;

  let recipients = createMessageFormData?.recipient?.include;

  const filtered_recipients = {
    include: recipients?.filter((item) => item !== 'all'),
  };

  const reactQuillHandler = (content, delta, source, editor) => {
    const slicedContent = content.trim();
    const characterCount = editor.getText().trim().length;
    const cursorPosition = editor?.getSelection()?.index;
    !isEmpty(cursorPosition) ? setWordCount(cursorPosition) : setWordCount('-');
    if (characterCount <= 1000) {
      setWordLimitWarning(false);
      setCreateMessageFormData({
        ...createMessageFormData,
        body: slicedContent,
      });
    } else {
      setWordLimitWarning(true);
    }
  };

  const validateMessageForm = () => {
    let title = createMessageFormData.title;
    let body = createMessageFormData.body;
    let organisation = createMessageFormData.organisation;
    let recipient = createMessageFormData.recipient;
    if (
      title === '' ||
      body === '' ||
      organisation === '' ||
      recipient.include.length === 0 ||
      wordLimitWarning
    ) {
      message.error('Please provide all required fields');
      setScheduleMessageModal(false);
      return false;
    } else {
      return true;
    }
  };

  const sendMessageFormHandler = (values) => {
    const isValidated = validateMessageForm();
    if (isValidated && buttonClicked === 'send') {
      dispatch(
        createOrgMessageAction({
          ...createMessageFormData,
          status: 1,
          files: [uploadFile],
          images: [uploadImage],
          recipient: filtered_recipients,
          organization: selectedOrganisationUUID,
        })
      );
    }

    if (isValidated && buttonClicked === 'draft') {
      dispatch(
        createOrgMessageAction({
          ...createMessageFormData,
          status: 2,
          recipient: filtered_recipients,
          files: [uploadFile],
          images: [uploadImage],
          organization: selectedOrganisationUUID,
        })
      );
    }

    console.log(createMessageFormData);
  };

  const scheduleMessageFormHandler = ({ date, time }) => {
    const validated = validateMessageForm();
    const schedule_datetime = `${moment(date._d).format('YYYY-MM-DD')} ${moment(
      time._d
    ).format('HH:mm:ss')}.00${timezone}`;
    if (validated) {
      setScheduleMessageModal(false);
      dispatch(
        createOrgMessageAction({
          ...createMessageFormData,
          status: 3,
          recipient: filtered_recipients,
          schedule_datetime,
          files: [uploadFile],
          images: [uploadImage],
        })
      );
    }
  };
  // const listOrgSearchHandler = (value) => {
  //   setSearchOrg(value);
  // };

  const listSubscriberSearchHandler = (value) => {
    setSearchSubscribers(value);
  };
  useEffect(() => {
    dispatch(resetImageUploadAction());
    dispatch(resetFileUploadAction());

    dispatch(viewOrganizationAction(auth?.currentUser?.organization));

    if (debouncedSearchValue !== '' && selectedOrganisationUUID) {
      const timer = setTimeout(() => {
        dispatch(
          viewOrganizationSubscriberAction({
            ...orgSubscribersInitialValue,
            search: debouncedSearchValue,
            organization_uuid: selectedOrganisationUUID,
          })
        );
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    } else if (selectedOrganisationUUID) {
      dispatch(
        viewOrganizationSubscriberAction({
          ...orgSubscribersInitialValue,
          organization_uuid: selectedOrganisationUUID,
        })
      );
    }
    // if (debouncedOrgSearchValue !== '') {
    //   const timer = setTimeout(() => {
    //     dispatch(
    //       listOrganizationAction({
    //         ...listOrgInitialValue,
    //         search: debouncedOrgSearchValue,
    //       })
    //     );
    //   }, 600);
    //   return () => {
    //     clearTimeout(timer);
    //   };
    // } else {
    //   dispatch(listOrganizationAction(listOrgInitialValue));
    // }
  }, [debouncedSearchValue, debouncedOrgSearchValue, selectedOrganisationUUID]); // eslint-disable-line

  const currentMessageInitialFormValue = {
    organization: currentOrg?.name,
    // recipient: 'allRecipients',
  };

  console.log(currentMessageInitialFormValue);

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
        <div className="w-full lg:w-2/3">
          <Card>
            <Form
              name="createMessage"
              autoComplete="off"
              layout="vertical"
              onFinish={sendMessageFormHandler}
              // initialValues={{
              //   organization: currentOrg?.name,
              // }}
              initialValues={currentMessageInitialFormValue}
            >
              <Form.Item
                className="mb-6"
                name="organization"
                label={t('org_message.create.org_label')}
                rules={[
                  {
                    required: true,
                    message: 'Please select organisation!',
                  },
                ]}
              >
                <Input size="large" disabled />
                {/* placeholder={currentOrg?.name}  */}
              </Form.Item>

              <Form.Item
                className="mb-6"
                name="recipient"
                label={t('org_message.create.recipients_label')}
                rules={[
                  {
                    required: true,
                    message: 'Please select recipient!',
                  },
                ]}
              >
                <Select
                  size="large"
                  labelInValue
                  filterOption={false}
                  showSearch
                  onSearch={listSubscriberSearchHandler}
                  placeholder={t('org_message.create.recipients_placeholder')}
                  mode="multiple"
                  maxTagCount={4}
                  defaultActiveFirstOption
                  disabled={selectedOrganisationUUID === null}
                  // onSelect={({ value }) => console.log('onSelect', value)}
                  onChange={(values) => {
                    setCreateMessageFormData({
                      ...createMessageFormData,
                      recipient: {
                        include: values?.map((item) => item?.value),
                      },
                    });
                  }}
                  onDeselect={({ value }) => {
                    setCreateMessageFormData({
                      ...createMessageFormData,
                      recipient: {
                        include: recipients?.filter((item) => item !== value),
                      },
                    });
                  }}
                >
                  {orgSubscribersLoading ? (
                    <Option>Loading...</Option>
                  ) : isEmpty(orgSubscribers?.results) ? (
                    <Option disabled value="none">
                      No subscriber found
                    </Option>
                  ) : (
                    <>
                      <Option
                        disabled={
                          recipients?.length >= 1 &&
                            !recipients?.includes(
                              'all' ||
                              typeof createMessageFormData?.recipient
                                ?.include[0] === 'number'
                            )
                            ? true
                            : false
                        }
                        value="all"
                      >
                        {recipients?.length > 0 &&
                          !recipients?.includes('all') ? (
                          <del>All Recipient</del>
                        ) : (
                          'All Recipient'
                        )}
                      </Option>
                      {orgSubscribers?.results?.slice(0, 5)?.map((item) => (
                        <Option
                          disabled={
                            recipients?.length === 1 &&
                            recipients?.includes('all')
                          }
                          key={item?.subscriber_id}
                          value={item?.subscriber_id}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                className="mb-6"
                name="title"
                label={t('org_message.create.msg_title_label')}
                rules={[
                  {
                    required: true,
                    message: 'Please provide message title!',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('org_message.create.msg_title_placeholder')}
                  // value={createMessageFormData.title}
                  onChange={(e) =>
                    setCreateMessageFormData({
                      ...createMessageFormData,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                className="mb-6"
                name="body"
                label="Message Content"
                tooltip="Max 1000 characters"
                rules={[
                  {
                    required: true,
                    message: 'Please write message body',
                  },
                ]}
              >
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  placeholder={t('org_message.create.msg_content_placeholder')}
                  // value={messageBody}
                  onChange={reactQuillHandler}
                />
              </Form.Item>
              <div className="flex justify-between items-center -mt-6 mb-2">
                <div className="text-red-500 text-sm">
                  {wordLimitWarning && 'Character limit exceeded!'}
                </div>
                <div className="text-grey-500 text-sm">
                  <span className={`${wordLimitWarning ? 'text-red-500' : ''}`}>
                    {wordCount}
                  </span>
                  /1000 characters
                </div>
              </div>
              <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                <div className="w-full lg:w-1/2">
                  <Form.Item
                    className="mb-6 file-uploader"
                    name="images"
                    label={t('org_message.create.image_label')}
                  >
                    <Input
                      size="large"
                      placeholder={t('org_message.create.image_placeholder')}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={imageUploadHandler}
                    />
                    {uploadImageLoading && 'Uploading...'}
                    {uploadImage && (
                      <img
                        src={uploadImage}
                        alt=""
                        className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="w-full lg:w-1/2">
                  <Form.Item
                    className="mb-6 file-uploader"
                    name="files"
                    label="Add File"
                  >
                    <Input
                      size="large"
                      placeholder={t('org_message.create.image_placeholder')}
                      type="file"
                      accept="application/pdf"
                      onChange={fileUploadHandler}
                    />
                    {uploadFileLoading && 'Uploading...'}
                    {uploadFile && (
                      <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                        <BiFile className="text-blue-link text-lg" />
                        <a
                          className="text-sm font-bold text-blue-link pl-2 truncate"
                          href={uploadFile ? uploadFile : '#'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          File Attachment
                        </a>
                      </div>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                <div className="w-full lg:w-1/2">
                  <Form.Item
                    className="mb-6"
                    name="videos"
                    label="Video URL"
                    rules={[
                      {
                        type: 'url',
                        message: 'This field must be a valid url.',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t('org_message.create.video_placeholder')}
                      onChange={(e) =>
                        setCreateMessageFormData({
                          ...createMessageFormData,
                          videos: [e.target.value],
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item className="mb-3">
                <div className="flex flex-col lg:flex-row lg:justify-between lg:space-x-8 text-center mt-4">
                  <Button
                    type="success"
                    className="w-full lg:w-1/3 mb-4 lg:mb-0"
                    size="large"
                    onClick={() => setButtonClicked('send')}
                    htmlType="submit"
                  >
                    {t('org_message.create.send_text')}
                  </Button>
                  <Button
                    type="warning"
                    className="w-full lg:w-1/3 mb-4 lg:mb-0"
                    size="large"
                    onClick={() => setButtonClicked('draft')}
                    htmlType="submit"
                  >
                    {t('org_message.create.draft_text')}
                  </Button>
                  <Button
                    type="primary"
                    className="w-full lg:w-1/3 mb-4 lg:mb-0"
                    size="large"
                    htmlType="submit"
                    onClick={() => setScheduleMessageModal(true)}
                  >
                    {t('org_message.create.schedule_text')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
        <div className="flex justify-center w-full lg:w-1/3">
          <div className="mockup-phone">
            <div className="message">
              {currentOrgLoading ? (
                <Space>
                  <Skeleton.Image className="mr-2 mb-1" />
                  <Skeleton.Input
                    style={{ width: 200 }}
                    active={currentOrgLoading}
                  />
                </Space>
              ) : (
                <div className="message-header">
                  <div className="flex items-center mb-2">
                    {currentOrg?.name ? (
                      <div className="logo">
                        <img src={currentOrg?.logo} alt="logo" />
                      </div>
                    ) : (
                      <Skeleton.Image className="mr-2 mb-1" />
                    )}

                    <div className="message-sender">
                      <div className="font-bold text-base mb-2 leading-none">
                        {currentOrg?.name
                          ? currentOrg?.name
                          : 'Organisation Name'}
                      </div>
                      <div className="text-xs text-grey-600">
                        {createMessageFormData?.organization
                          ? new Date().toLocaleString() + ''
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="message-header-title text-base font-bold leading-5 border-b border-grey-300 pb-4 mb-4">
                    {createMessageFormData?.title
                      ? createMessageFormData?.title
                      : 'Message Title'}
                  </div>
                </div>
              )}
              <div className="message-content">
                <div
                  className="pb-4"
                  dangerouslySetInnerHTML={{
                    __html: `${createMessageFormData?.body
                        ? createMessageFormData?.body
                        : 'Message Body'
                      }`,
                  }}
                ></div>
                {uploadImage && (
                  <img
                    src={uploadImage}
                    alt=""
                    className="w-full h-auto p-2 mt-3 border border-grey-300 mx-auto rounded"
                  />
                )}
              </div>
              <div className="message-footer px-4 pb-5">
                {!isEmpty(createMessageFormData?.videos[0]) && (
                  <ReactPlayer
                    className="youtube-embed"
                    width="100%"
                    height="auto"
                    url={createMessageFormData?.videos[0]}
                    config={{ youtube: { playerVars: { showinfo: 1 } } }}
                  />
                )}
                {uploadFile && (
                  <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                    <BiFile className="text-blue-link text-lg" />
                    <a
                      className="text-sm font-bold text-blue-link pl-2"
                      href={uploadFile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File Attachment
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={scheduleMessageModal}
        onOk={() => setScheduleMessageModal(false)}
        onCancel={() => setScheduleMessageModal(!scheduleMessageModal)}
        footer={false}
        centered
      >
        <div className="py-4">
          <div className="text-xl font-bold mb-4 text-center">
            Schedule Message
          </div>
          <Form
            name="createMessage"
            autoComplete="off"
            layout="vertical"
            onFinish={scheduleMessageFormHandler}
          >
            <Form.Item
              className="mb-6"
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: 'Please select date!',
                },
              ]}
            >
              <DatePicker
                className="w-full"
                size="large"
                format="YYYY-MM-DD"
                disabledDate={(current) =>
                  current && current < moment().startOf('day')
                }
              />
            </Form.Item>
            <Form.Item
              className="mb-8"
              name="time"
              label={`Time (Timezone: ${zone}/${timezone})`}
              format="HH:mm:ss"
              rules={[
                {
                  required: true,
                  message: 'Please select time!',
                },
              ]}
            >
              <TimePicker
                className="w-full"
                size="large"
                disabledTime={(current) =>
                  current && current < moment().startOf('minute')
                }
              />
            </Form.Item>
            <Form.Item>
              <div className="flex justify-between">
                <Button
                  size="large"
                  type="success"
                  className="w-64 mr-4"
                  htmlType="submit"
                  onClick={() => setButtonClicked('schedule')}
                >
                  Schedule Message
                </Button>
                <Button
                  size="large"
                  onClick={() => setScheduleMessageModal(false)}
                  className="w-64"
                >
                  Close
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default CreateNewMessage;
