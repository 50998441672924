import React from 'react';
import { useRouteMatch } from 'react-router';
import SectionTitle from '../../components/dashboard/section-title';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumbs';
import { BiPlusCircle } from 'react-icons/bi';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import OrgMessageTable from './OrgMessageTable';
import { useSelector } from 'react-redux';

function OrgMessage() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  let title = t('org_message.section_title');
  let breadcrumbsItem = [
    // { home: false, title: t('left_sidebar.dashboard'), url: '/', last: false },
    { title, url: '/', last: true },
  ];

  const { currentUser } = useSelector((state) => state.auth);

  console.log(currentUser?.organization)

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex lg:flex-row-reverse mb-6">
        <Link to={`${url}/new`}>
          <Button
            className="flex justify-center items-center"
            type="success"
            size="large"
            icon={<BiPlusCircle className="mr-2 w-6 h-6" />}
          >
            {t('org_message.create.button_text')}
          </Button>
        </Link>
      </div>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <OrgMessageTable organization_uuid={currentUser?.organization} />
      </Card>
    </>
  );
}

export default OrgMessage;
