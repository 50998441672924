import axiosClient from './config';

export const listOrgUsersService = async ({ page, role, search, ordering, organization_uuid, created_at_after, created_at_before }) => {
  return await axiosClient.get(
    `/organisation-users/?org_uuid=${organization_uuid}&page=${page}&role=${role}&search=${search}&ordering=${ordering}&created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};

export const createOrgUserService = async (payload) => {
  return await axiosClient.post('/organisation-users/', payload);
};

export const editOrgUserService = async (name, role, uuid) => {
  return await axiosClient.patch(`/organisation-users/${uuid}/`, {
    name,
    role,
  });
};

export const viewOrgUserService = async (userId) => {
  return await axiosClient.get(`/organisation-users/${userId}/`);
};

export const changeOrgUserStatusService = async (status, uuid) => {
  return await axiosClient.patch(`/organisation-users/${uuid}/status/`, {
    status,
  });
};

export const updateOrgUserPasswordService = async (profile_uuid, password) => {
  return await axiosClient.patch(
    `/organisation-users/${profile_uuid}/update-password/`,
    {
      password,
    }
  );
};
