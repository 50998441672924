export const LIST_organizationS = 'LIST_organizationS';
export const LIST_organizationS_SUCCESS = 'LIST_organizationS_SUCCESS';
export const LIST_organizationS_FAILED = 'LIST_organizationS_FAILED';

export const CREATE_organization = 'CREATE_organization';
export const CREATE_organization_SUCCESS = 'CREATE_organization_SUCCESS';
export const CREATE_organization_FAIL = 'CREATE_organization_FAIL';

export const EDIT_organization = 'EDIT_organization';
export const EDIT_organization_SUCCESS = 'EDIT_organization_SUCCESS';
export const EDIT_organization_FAIL = 'EDIT_organization_FAIL';

export const VIEW_organization = 'VIEW_organization';
export const VIEW_organization_SUCCESS = 'VIEW_organization_SUCCESS';
export const VIEW_organization_FAIL = 'VIEW_organization_FAIL';

export const VIEW_organization_SUBSCRIBER = 'VIEW_organization_SUBSCRIBER';
export const VIEW_organization_SUBSCRIBER_SUCCESS = 'VIEW_organization_SUBSCRIBER_SUCCESS';
export const VIEW_organization_SUBSCRIBER_FAIL = 'VIEW_organization_SUBSCRIBER_FAIL';

export const DELETE_organization = 'DELETE_organization';
export const DELETE_organization_SUCCESS = 'DELETE_organization_SUCCESS';
export const DELETE_organization_FAIL = 'DELETE_organization_FAIL';
