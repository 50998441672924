import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import dateFormat from '../../utils/dateFormat';
import { Button, Popover, Tooltip } from 'antd';
import { BiCalendar, BiX } from 'react-icons/bi';
import moment from 'moment';

function Index({ datePickerHandler }) {
  const dateRangeInitialValue = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [dateRangeOptions, setDateRangeOptions] = useState(
    dateRangeInitialValue
  );
  const handleSelect = (ranges) => {
    setDateRangeOptions(ranges.selection);
  };
  const dateHandler = () => {
    const { startDate, endDate } = dateRangeOptions;
    setIsDatePickerVisible(!isDatePickerVisible);
    setIsFilterActive(!isFilterActive);
    datePickerHandler({
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    });
  };
  const resetFilter = () => {
    setIsFilterActive(false);
    setIsDatePickerVisible(false);
    datePickerHandler({
      startDate: '',
      endDate: '',
    });
    setDateRangeOptions(dateRangeInitialValue);
  };
  const content = (
    <div className="flex flex-col col-span-3 mx-auto mt-2 transition duration-5000 ease-in">
      <DateRangePicker
        ranges={[dateRangeOptions]}
        maxDate={new Date()}
        months={2}
        direction="horizontal"
        calendarFocus="backwards"
        preventSnapRefocus={true}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        rangeColors={['#22C55E']}
        onChange={handleSelect}
      />
      <div className="flex justify-between">
        <div className="order-first">
          {isFilterActive && (
            <Button className="w-64 mr-4" type="primary" onClick={resetFilter}>
              Reset Filter
            </Button>
          )}
        </div>
        <div className="order-last">
          <Button
            size="large"
            className="w-64 mr-4"
            onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="success"
            className="w-64 mr-4"
            onClick={dateHandler}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
  return (
    <div className="relative">
      <Popover
        content={content}
        trigger="click"
        visible={isDatePickerVisible}
        className="flex justify-center items-center"
        onClick={() => setIsDatePickerVisible(true)}
        placement="bottom"
      >
        <div
          className={
            `font-bold cursor-pointer pt-2 relative hover:text-blue-anak ${isFilterActive ? 'text-blue-anak' : ''
            }`}
        >
          {isFilterActive
            ? `${dateFormat(dateRangeOptions?.startDate)} - ${dateFormat(
              dateRangeOptions?.endDate
            )}`
            : 'Filter by Date'}

          <BiCalendar className={`ml-1 ${isFilterActive ? 'text-blue-anak' : ''}`} />
        </div>
      </Popover>
      {isFilterActive && (
        <div
          onClick={resetFilter}
          className="absolute cursor-pointer bg-white z-10"
          style={{ right: 1, top: 13 }}
        >
          <Tooltip title="Clear Filter">
            <BiX className="w-3 h-3 text-white bg-blue-anak rounded-full" />
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default Index;
