import { put, all, takeLatest } from 'redux-saga/effects';
import * as types from '../store/actionTypes/organizations';
import { notification } from 'antd';
import {
  listOrganizationsService,
  getOrganizationService,
  getOrganizationSubscriberService,
  createOrganizationService,
  editOrganizationService,
  deleteOrganizationService,
} from '../services/organizationService';
import {
  listOrganizationSuccessAction,
  listOrganizationFailAction,
  viewOrganizationSuccessAction,
  viewOrganizationFailAction,
  createOrganizationSuccessAction,
  createOrganizationFailAction,
  editOrganizationSuccessAction,
  editOrganizationFailAction,
  deleteOrganizationSuccessAction,
  deleteOrganizationFailAction,
  viewOrganizationSubscriberSuccessAction,
  viewOrganizationSubscriberFailAction,
} from '../store/actions/organizationActions';
import history from '../utils/history';

//Worker
export function* listOrganizations({ payload }) {
  try {
    const list = yield listOrganizationsService(payload);
    yield put(listOrganizationSuccessAction(list.data.data));
  } catch (error) {
    yield put(listOrganizationFailAction(error));
  }
}
export function* getOrganizationDetails({ payload: OrgCategoryUuid }) {
  try {
    const details = yield getOrganizationService(OrgCategoryUuid);
    yield put(viewOrganizationSuccessAction(details.data.data));
  } catch (error) {
    yield put(viewOrganizationFailAction(error));
  }
}
export function* getOrganizationSubscribers({ payload: OrgCategoryUuid }) {
  try {
    const details = yield getOrganizationSubscriberService(OrgCategoryUuid);
    yield put(viewOrganizationSubscriberSuccessAction(details.data.data));
  } catch (error) {
    yield put(viewOrganizationSubscriberFailAction(error));
  }
}

export function* createOrganization({ payload: category_name }) {
  try {
    const create = yield createOrganizationService(category_name);
    yield put(createOrganizationSuccessAction(create));
    notification['success']({
      message: `organization created successfully.`,
      description: 'Success description',
    });
  } catch (error) {
    yield put(createOrganizationFailAction(error));
    notification['error']({
      message: 'Failed to create category.',
      description: 'Please retry or contact administration.',
    });
  }
}
export function* editOrganization({
  payload: { formValue, organization_uuid },
}) {
  try {
    const edit = yield editOrganizationService(organization_uuid, formValue);
    yield put(editOrganizationSuccessAction(edit));
    history.push('/org-details');
    notification['success']({
      message: `Organization updated successfully.`,
      description: 'Success description',
    });
  } catch (error) {
    yield put(editOrganizationFailAction(error));
    notification['error']({
      message: 'Failed to update Organization.',
      description: 'Please retry or contact administration.',
    });
  }
}
export function* deleteOrganization({ payload: category_uuid }) {
  try {
    yield deleteOrganizationService(category_uuid);
    yield put(deleteOrganizationSuccessAction());
    notification['success']({
      message: `organization removed successfully.`,
      description: 'Success description',
    });
  } catch (error) {
    yield put(deleteOrganizationFailAction(error));
    notification['error']({
      message: 'Failed to delete organization.',
      description: 'Please retry or contact administration.',
    });
  }
}

//Watcher
export function* onListOrganizationsRequest() {
  yield takeLatest(types.LIST_organizationS, listOrganizations);
}
export function* onGetOrganizationDetails() {
  yield takeLatest(types.VIEW_organization, getOrganizationDetails);
}
export function* onGetOrganizationSubscribers() {
  yield takeLatest(
    types.VIEW_organization_SUBSCRIBER,
    getOrganizationSubscribers
  );
}
export function* onCreateOrganization() {
  yield takeLatest(types.CREATE_organization, createOrganization);
}
export function* onEditOrganization() {
  yield takeLatest(types.EDIT_organization, editOrganization);
}
export function* onDeleteOrganization() {
  yield takeLatest(types.DELETE_organization, deleteOrganization);
}

export function* organizationSaga() {
  yield all([
    onListOrganizationsRequest(),
    onGetOrganizationDetails(),
    onGetOrganizationSubscribers(),
    onCreateOrganization(),
    onEditOrganization(),
    onDeleteOrganization(),
  ]);
}
