import axiosClient from './config';

export const listOrganizationsService = async ({
  page,
  search,
  ordering,
  page_size,
}) => {
  return await axiosClient.get(
    `/organisations/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}`
  );
};
export const getOrganizationService = async (organization_uuid) => {
  return await axiosClient.get(`/organisations/${organization_uuid}/`);
};
export const getOrganizationSubscriberService = async ({ organization_uuid }) => {
  return await axiosClient.get(
    `/organisations/${organization_uuid}/subscribers/`
  );
};
export const createOrganizationService = async (details) => {
  return await axiosClient.post('/organisations/', details);
};
export const editOrganizationService = async (uuid, details) => {
  return await axiosClient.patch(`/organisations/${uuid}/`, details);
};
export const deleteOrganizationService = async (organization_uuid) => {
  return await axiosClient.delete(`/organisations/${organization_uuid}/`);
};