import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BiFilterAlt, BiSearchAlt, BiX } from 'react-icons/bi';
import {
  Table,
  Menu,
  Dropdown,
  Select,
  Button,
  Tooltip,
  Alert,
  Input,
} from 'antd';
import TableDateRange from './table-date-range';
import { useTranslation } from 'react-i18next'

function TableUniversal({
  initialTableParams,
  menuFilter,
  dispatchAction,
  menuFilterBy,
  data,
  loading,
  error,
  children,
}) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [tableParams, setTableParams] = useState(initialTableParams);
  const { Option } = Select;
  const dispatch = useDispatch();

  const resetFilter = () => {
    tableParams[menuFilterBy] = '';
    setIsFilterActive(false);
    setTableParams({
      ...tableParams,
    });
  };
  const handleMenuClick = (e) => {
    resetFilter();
    tableParams[menuFilterBy] = e.key;
    setTableParams({ ...tableParams, page: 1 });
    setIsFilterActive(true);
  };
  const pageSizeHandler = (page_size) => {
    setTableParams({ ...tableParams, page_size });
  };
  const tableActionHandler = (pagination, filters, sorter, extra) => {
    const ordering =
      sorter.order === 'ascend'
        ? sorter.field
        : sorter.order === 'descend'
          ? `-${sorter.field}`
          : '';
    const page = pagination.current;
    setTableParams({
      ...tableParams,
      ordering,
      page,
    });
  };
  const menuOrg = (
    <Menu onClick={handleMenuClick}>
      {menuFilter.map((item) => (
        <Menu.Item key={item.key}>{item.value}</Menu.Item>
      ))}
    </Menu>
  );
  const datePickerHandler = ({ startDate = '', endDate = '' }) => {
    setTableParams({
      ...tableParams,
      page: 1,
      created_at_after: startDate,
      created_at_before: endDate,
    });
  };
  const searchInputHandler = (e) => {
    setSearchValue(e.target.value.toLowerCase());
    setTableParams({
      ...initialTableParams,
      search: e.target.value.toLowerCase(),
    });
  };
  useEffect(() => {
    if (searchValue !== '') {
      const timer = setTimeout(() => {
        dispatch(dispatchAction(tableParams));
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    } else {
      dispatch(dispatchAction(tableParams));
    }
  }, [tableParams, searchValue]); //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="grid grid-cols-3 sm:grid-cols-6 md:grid-cols-12 gap-4 px-4 pb-4">
        <div className="hidden sm:block col-span-3 order-last md:order-first">
          {t('table.pagination.filter_show')}
          <Select
            className="px-2"
            defaultValue={tableParams.page_size}
            onChange={pageSizeHandler}
          >
            <Option value="10">10</Option>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
          </Select>
          {t('table.pagination.filter_entries')}
        </div>
        <div className="col-span-5">
          <Input
            className="rounded-lg lg:max-w-sm table-search"
            type="text"
            placeholder={t('table.search_placeholder')}
            onChange={searchInputHandler}
            prefix={<BiSearchAlt />}
          />
        </div>
        <div className="col-span-2 md:col-span-3 justify-self-center md:justify-self-end">
          <TableDateRange datePickerHandler={datePickerHandler} />
        </div>
        {menuFilter.length !== 0 && (
          <div className="col-span-1 relative flex sm:border-l border-grey-200 pt-1">
            {isFilterActive && (
              <div
                onClick={resetFilter}
                className="absolute cursor-pointer bg-white z-10"
                style={{ right: 10, top: 13 }}
              >
                <Tooltip title={t('table.filter.clear_filter')}>
                  <BiX className="w-3 h-3 text-white bg-blue-anak rounded-full" />
                </Tooltip>
              </div>
            )}
            <Dropdown
              overlayStyle={{ width: '180px' }}
              placement="bottomRight"
              overlay={menuOrg}
              className="cursor-pointer"
              trigger={['click']}
            >
              <Button
                type="link"
                className={`flex items-center ${isFilterActive ? 'btn-active' : ''
                  }`}
              >
                Filter <BiFilterAlt className="ml-1" />
              </Button>
            </Dropdown>
          </div>
        )}
      </div>
      {error ? (
        <Alert
          message="Failed to load table. Retry after sometime."
          type="error"
          showIcon
        />
      ) : (
        <Table
          dataSource={!loading && data?.results}
          bordered={true}
          rowKey={(record) => record.id || record.uuid}
          scroll={{ x: true }}
          onChange={tableActionHandler}
          loading={loading}
          pagination={{
            className: 'px-4 right-align',
            showSizeChanger: false,
            current: tableParams.page,
            pageSize: tableParams.page_size,
            total: data?.count,
            showTotal: (total, range) =>
              ` ${t('table.pagination.index_text_showing')}  ${range[0]} ${t('table.pagination.index_text_to')} ${range[1]} ${t('table.pagination.index_text_of')} ${total} ${t('table.pagination.index_text_entries')}`,
          }}
        >
          {children}
        </Table>
      )}
    </>
  );
}

export default TableUniversal;
