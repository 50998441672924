import { put, all, takeEvery, delay } from 'redux-saga/effects';
import * as types from '../store/actionTypes';
import {
  listCustomerSuccessAction,
  listCustomerFailedAction,
  viewCustomerSuccessAction,
  viewCustomerFailAction
} from '../store/actions/customerActions';
import { listCustomerService, viewCustomerService } from '../services/customerService';

// import { isEmpty } from '../utils/generic';

//Worker
export function* listCustomer({ payload }) {
  try {
    yield delay(1000);
    const list = yield listCustomerService(payload);
    yield put(listCustomerSuccessAction(list.data.data));
  } catch (error) {
    yield put(listCustomerFailedAction(error));
  }
}

export function* viewCustomer({ payload: { organisations_uuid, subscriber_id} }) {
  try {
    const list = yield viewCustomerService(organisations_uuid, subscriber_id);
    yield put(viewCustomerSuccessAction(list.data));
  } catch (error) {
    yield put(viewCustomerFailAction(error));
  }
}

//Watcher
export function* onListRequest() {
  yield takeEvery(types.LIST_CUSTOMER, listCustomer);
}

export function* onViewCustomer() {
  yield takeEvery(types.VIEW_CUSTOMER_DETAILS, viewCustomer);
}

export function* customerSaga() {
  yield all([
    onListRequest(),
    onViewCustomer(),
  ]);
}
