import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import BoxedShadow from '../../components/widget/boxed-shadow';
import { Form, Input, Button, Select, Modal, Spin } from 'antd';
import ReactQuill from 'react-quill';
import { reactQuillModules, reactQuillFormats } from './data';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	editOrganizationAction,
	// editOrganizationAction,
	viewOrganizationAction,
} from '../../store/actions/organizationActions';
import { listOrgCategoryAction } from '../../store/actions/orgCategoryActions';
import { useParams } from 'react-router-dom';
import { isEmpty } from '../../utils/generic';
import { imageUploadHandler } from '../../utils/upload';

function EditOrganization() {
	const { organization_uuid } = useParams();
	const [languageSelect, setLanguageSelect] = useState(['_en']);
	const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false);
	const [orgDescription, setOrgDescription] = useState({});
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { uploadReducer, orgCategoryReducer, organization } = useSelector(
		(state) => ({
			uploadReducer: state.uploadReducer,
			orgCategoryReducer: state.orgCategoryReducer,
			organization: state.organization,
		}),
		shallowEqual
	);
	const { uploadImage, uploadImageLoading } = uploadReducer;
	const {
		orgCategoriesList,
		orgCategoriesListLoading,
		orgCategoriesListError,
	} = orgCategoryReducer;
	const { currentOrg, currentOrgLoading } = organization;
	let title = t('org_details.edit_modal.title');
	let breadcrumbsItem = [
		{
			home: false,
			title: 'Org Details',
			url: '/org-details',
			last: false,
		},
		{ title, url: '/', last: true },
	];
	const editOrgInitialValue = {
		name: currentOrg?.name,
		category: currentOrg?.category,
		category_uuid: currentOrg?.category_uuid,
		logo: currentOrg?.logo,
		email: currentOrg?.email,
		phone: currentOrg?.phone,
		website: currentOrg?.website,
	};

	const { Option } = Select;
	const addLanguageHandler = ({ selectedLanguage }) => {
		if (languageSelect.includes(selectedLanguage)) {
			setLanguageSelect([...languageSelect]);
		} else {
			setLanguageSelect([...languageSelect, selectedLanguage]);
		}
		setIsLanguageModalVisible(false);
	};
	const removeLanguageHandler = (lang) => {
		const filtered = [...languageSelect].filter((item) => item !== lang);
		let newDesc = { ...orgDescription };
		delete newDesc[lang];
		setOrgDescription(newDesc);
		setLanguageSelect(filtered);
	};
	const editOrgFormHandler = (values) => {
		const updatedFormValue = {
			...values,
			description: { ...currentOrg?.description, ...orgDescription },
			category: currentOrg?.category_uuid,
			logo: uploadImage || currentOrg?.logo,
		};
		dispatch(editOrganizationAction(organization_uuid, updatedFormValue));
		console.log(updatedFormValue);
	};

	useEffect(() => {
		if (isEmpty(organization.currentOrg)) {
			dispatch(viewOrganizationAction(organization_uuid));
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<SectionTitle
				title={title}
				right={<Breadcrumb items={breadcrumbsItem} />}
			/>
			{currentOrgLoading ? (
				<div className="flex justify-center">
					<Spin />
				</div>
			) : (
				<div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
					<BoxedShadow className="w-full lg:w-2/3">
						<Form
							name="createMessage"
							autoComplete="off"
							layout="vertical"
							onFinish={editOrgFormHandler}
							initialValues={organization_uuid ? editOrgInitialValue : null}
						>
							<div className="flex flex-col ">
								<div className="">
									<Form.Item
										name="name"
										label={t('form.org_name_label')}
										className="mb-6"
										rules={[
											{
												required: true,
												message: t('form.org_name_error'),
											},
										]}
									>
										<Input
											size="large"
											placeholder={t('add_Organization.name_placeholder')}
										/>
									</Form.Item>
								</div>

								<div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
									<div className="w-full lg:w-1/2 mb-4 lg:mb-0">
										<Form.Item
											className="mb-6"
											name="category"
											label={t('form.org_category_label')}
											rules={[
												{
													required: true,
													message: t('form.org_category_error'),
												},
											]}
										>
											<Select
												size="large"
												placeholder={t('form.org_category_placeholder')}
												onClick={
													!orgCategoriesList?.count > 0
														? () =>
															dispatch(
																listOrgCategoryAction({
																	page: 1,
																	search: '',
																	ordering: '',
																	page_size: 10,
																	created_at_after: '',
																	created_at_before: '',
																})
															)
														: null
												}
											>
												{!orgCategoriesListLoading &&
													orgCategoriesList?.count > 0
													? orgCategoriesList.results?.map((item) => (
														<Option key={item?.uuid} value={item?.uuid}>
															{item?.name}
														</Option>
													))
													: 'Loading...'}
												{orgCategoriesListError &&
													'Failed to load org categories'}
											</Select>
										</Form.Item>
									</div>

									<div className="w-full lg:w-1/2">
										<Form.Item
											className="mb-6 file-uploader"
											name="logo"
											label={t('form.org_logo_label')}
										>
											<Input
												size="large"
												placeholder={t('add_Organization.logo_placeholder')}
												type="file"
												onChange={imageUploadHandler}
											/>
											{!uploadImageLoading && uploadImage ? (
												<img
													src={uploadImage}
													alt=""
													className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
												/>
											) : (
												<img
													src={currentOrg?.logo}
													alt=""
													className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
												/>
											)}
										</Form.Item>
									</div>
								</div>
								<div>
									{languageSelect.map((lang, i) => (
										<Form.Item
											key={i}
											className="mb-6 relative"
											label={`Description (${lang === '_en' ? 'English' : 'Bahasa Indonesia'
												})`}
										>
											{lang !== '_en' && (
												<div
													className="absolute right-0 text-red-anak font-bold cursor-pointer"
													onClick={() => removeLanguageHandler(lang)}
													style={{ top: '-31px' }}
												>
													{t('form.remove_language')}
												</div>
											)}
											<ReactQuill
												theme="snow"
												onChange={(e) => {
													const newDesc = { ...orgDescription, [lang]: e };
													setOrgDescription(newDesc);
												}}
												modules={reactQuillModules}
												formats={reactQuillFormats}
												placeholder={t('form.org_desc_placeholder')}
												defaultValue={currentOrg?.description?.[lang]}
											/>
										</Form.Item>
									))}
								</div>

								<div className="mb-6 text-right">
									<Button
										type="primary"
										className="px-6 rounded"
										onClick={() =>
											setIsLanguageModalVisible(!isLanguageModalVisible)
										}
									>
										{t('form.add_language')}
									</Button>
								</div>

								<div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
									<div className="w-full lg:w-1/2">
										<Form.Item
											className="mb-6"
											name="email"
											label={t('form.contact_email_label')}
											rules={[
												{ required: true, message: 'Please input email!' },
												{
													type: 'email',
													message: 'Please input valid email id!',
												},
											]}
										>
											<Input
												size="large"
												placeholder={t('form.contact_email_placeholder')}
											/>
										</Form.Item>
									</div>

									<div className="w-full lg:w-1/2">
										<Form.Item
											className="mb-6"
											name="phone"
											label={t('form.contact_phone_label')}
											rules={[
												{
													required: true,
													message: t('form.contact_phone_error'),
												},
											]}
										>
											<Input
												size="large"
												placeholder={t('form.contact_phone_placeholder')}
											/>
										</Form.Item>
									</div>
								</div>

								<div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
									<div className="w-full lg:w-1/2">
										<Form.Item
											className="mb-6"
											name="website"
											label={t('form.org_website_label')}
											rules={[
												{
													required: true,
													message: t('form.org_website_error'),
												},
											]}
										>
											<Input
												size="large"
												placeholder={t('form.org_website_placeholder')}
											/>
										</Form.Item>
									</div>
									<div className="w-full lg:w-1/2"></div>
								</div>
								<div className="flex mt-6">
									<Button
										type="success"
										size="large"
										className="mr-4 w-full lg:w-64 mb-4 lg:mb-0"
										htmlType="submit"
									>
										{t('form.save_text')}
									</Button>
								</div>
							</div>
						</Form>
					</BoxedShadow>
				</div>
			)}

			<Modal
				title="Select description language"
				visible={isLanguageModalVisible}
				// onOk={addLanguageHandler}
				onCancel={() => setIsLanguageModalVisible(!isLanguageModalVisible)}
				centered
				footer={null}
			>
				<Form layout="vertical" className="" onFinish={addLanguageHandler}>
					<Form.Item
						name="selectedLanguage"
						label="Select Language"
						className="mb-6"
						rules={[
							{
								required: true,
								message: 'Please select a language',
							},
						]}
					>
						<Select size="large" placeholder="Select Language">
							<Option value="_in">Bahasa Indonesia</Option>
						</Select>
					</Form.Item>
					<div className="flex justify-between">
						<Button
							size="large"
							htmlType="submit"
							type="success"
							className="w-64 mr-4"
						>
							Add
						</Button>
						<Button
							size="large"
							onClick={() => setIsLanguageModalVisible(!isLanguageModalVisible)}
							className="w-64"
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal>
		</>
	);
}

export default EditOrganization;
