import * as types from "../actionTypes";

// SYSTEM USER TABLE LISTING
export const listMessagesAction = () => {
  return {
    type: types.LIST_MESSAGES_REQUEST,
  };
};

export const listMessagesSuccessAction = (messagesList) => {
  return {
    type: types.LIST_MESSAGES_SUCCESS,
    payload: messagesList,
  };
};

export const listMessagesFailedAction = (error) => {
  return {
    type: types.LIST_MESSAGES_FAILED,
    payload: error,
  };
};
