import * as types from '../actionTypes/index';

export const listCustomerAction = (tableParams) => {
  return {
    type: types.LIST_CUSTOMER,
    payload: tableParams,
  };
};

export const listCustomerSuccessAction = (customers) => {
  return {
    type: types.LIST_CUSTOMER_SUCCESS,
    payload: customers,
  };
};

export const listCustomerFailedAction = (error) => {
  return {
    type: types.LIST_CUSTOMER_FAILED,
    payload: error,
  };
};

export const viewCustomerAction = ( organisations_uuid, subscriber_id) => {
  return {
    type: types.VIEW_CUSTOMER_DETAILS,
    payload: {subscriber_id, organisations_uuid}
  };
};

export const viewCustomerSuccessAction = (currentCustomer) => {
  return {
    type: types.VIEW_CUSTOMER_DETAILS_SUCCESS,
    payload: currentCustomer,
  };
};

export const viewCustomerFailAction = (error) => {
  return {
    type: types.VIEW_CUSTOMER_DETAILS_FAILED,
    payload: error,
  };
};