const translationBahasaIndonesia = {
  left_sidebar: {
    dashboard: 'Dasbor',
    org_message: 'Pesan Org',
    org_details: 'Detail Org',
    org_user: 'Pengguna Org',
    customer_list: 'Pelanggan'
  },
  navbar: {
    user_dropdown: {
      logout: 'Keluar',
      editProfile: 'Sunting profil',
    },
  },
  profileEdit: {
    section_title: 'Informasi pribadi',
    profile_pic: 'Foto Profil',
    upload_photo: 'Mengunggah foto',
    delete_photo: 'Menghapus foto',
    save_photo: 'Menyimpan foto',
    full_name: 'Nama lengkap',
    email: 'Surel',
    email_helpText: 'Untuk mengubah id email Anda. silakan',
    email_helpText_link: 'kirim permintaan ke Super Admin',
    reset_password_label: 'Setel Ulang Kata Sandi',
    password_placeholder: 'Masukkan Kata Sandi Saat Ini',
    password_helpText1: 'Lupa kata sandi Anda saat ini? Tolong',
    password_helpText2: 'klik disini',
    password_helpText3: 'untuk mendapatkan tautan reset kata sandi',
    new_password_placeholder: 'Masukan kata sandi baru',
    confirm_password_placeholder: 'Setujui password',
    btn_save: 'Simpan perubahan',
    btn_cancel: 'Membatalkan',
    error_password: 'Silakan masukkan kata sandi Anda saat ini!',
    error_new_password: 'Silakan masukkan kata sandi baru!',
    error_password_match: 'Kata sandi baru tidak cocok',
  },
  dashboard: {
    orgDetails: {
      category: 'Kategori',
      status: 'Status'
    },
    customer_details: {
      section_title: 'Semua Detail Pelanggan',
      graph_title_subscribed: 'Pelanggan Berlangganan berdasarkan Bulan',
      graph_title_unsubscribed: 'Pelanggan Berhenti Berlangganan menurut Bulan',
      subscribed_customers: 'Pelanggan Berlangganan',
      unsubscribed_customers: 'Pelanggan yang Tidak Berlangganan',
      total_customers: 'Jumlah Pelanggan',
    },
    message_details: {
      section_title: 'Detail Pesan',
      graph_title_all: 'Semua Pesan berdasarkan Bulan',
      graph_title_published: 'Pesan yang Diterbitkan berdasarkan Bulan',
      graph_title_scheduled: 'Pesan yang Diterbitkan berdasarkan Bulan',
      graph_title_draft: 'Draf Pesan berdasarkan Bulan',
      all_msgs: 'Semua Pesan',
      published_msgs: 'Pesan yang Dipublikasikan',
      scheduled_msgs: 'Pesan Terjadwal',
      draft_msgs: 'Draf Pesan',
    },
  },
  customer_list: {
    section_title: 'Daftar pelanggan',
  },
  customer_details: {
    section_title: 'Detail Pelanggan',
  },
  table: {
    search_placeholder: 'Mencari',

    pagination: {
      filter_show: 'Menunjukkan',
      filter_entries: 'Entri',
      index_text_showing: 'Menampilkan',
      index_text_to: 'hingga',
      index_text_of: 'dari',
      index_text_entries: 'entri',
    },
    thead: {
      serial_number: 'Sl. No',
      user_name: 'Nama',
      user_mail: 'Surel',
      user_phone: 'No Telepon',
      user_registered_on: 'Registerd Pada',
      user_created_at: 'Dibuat di',
      user_updated_at: 'Diperbarui Pada',
      user_created_by: 'Dibuat oleh',
      user_last_login: 'Login Terakhir',
      actions: 'Tindakan',
      subscribed_on: 'Berlangganan Pada',
      unsubscribed_on: 'Berhenti berlangganan pada',
      msg_heading: 'Pesan Menuju',
      msg_org_name: 'Nama Organisasi',
      msg_timestamp: 'Tanggal Waktu',
      msg_views: 'Tampilan Pengguna',
      msg_language: 'Bahasa',
      msg_status_subscribed: 'Berlangganan',
      msg_status_unsubscribed: 'Tidak berlangganan',
      status: 'Status',
      actions_btn: 'Melihat Rincian',
      actions_edit_btn: 'Sunting Pesan',
      subscriptionStatus: 'Status Berlangganan',
      subscribed: 'Berlangganan',
      unsubscribed: 'Tidak berlangganan',
    },
    filter: {
      sent_messages: 'Pesan terkirim',
      draft_messages: 'Draf Pesan',
      scheduled_messages: 'Pesan Terjadwal',
      filter_label: 'Menyaring',
      filter_date: 'Filter By Date',
      clear_filter: 'Hapus Filter'
    },
    alerts: {
      load_error: 'Gagal memuat tabel. Coba lagi setelah beberapa waktu.',
      passwordUpdated: 'Kata Sandi Diperbarui'
    },
    status: {
      active: 'Aktif',
      inactive: 'Tidak Aktif',
    }
  },
  org_details: {
    section_title: 'Detail Organisasi',
    status: 'Status',
    category: 'Kategori',
    tab_description: 'Keterangan',
    tab_Users: 'Pengguna',
    tab_message: 'Pesan',
    tab_contact: 'Detail Kontak',
    selectLanguage: 'Pilih bahasa',
    edit_modal: {
      button_text: 'Edit Organisasi',
      title: 'Edit Organisasi',
      // ADD MORE FROM add_organization
    },
    delete_modal: {
      button_text: 'Hapus Organisasi',
      title: 'Hapus Organisasi',
      description: 'Apakah Anda yakin ingin menghapus organisasi ini?',
      confirm_text: 'Menghapus',
      cancel_text: 'Membatalkan',
    },
  },
  add_organization: {
    button_text: 'Tambahkan Organisasi Baru',
    title: 'Tambahkan Organisasi Baru',
    name_label: 'nama organisasi',
    name_placeholder: 'Masukkan Nama Organisasi',
    category_label: 'Kategori Organisasi',
    category_placeholder: 'Pilih Kategori Organisasi',
    logo_label: 'Logo Organisasi',
    logo_placeholder: 'Tidak ada file yang dipilih',
    description_label: 'Keterangan',
    description_placeholder: 'Ketik isi pesan',
    add_language: 'Tambahkan Bahasa Baru',
    email_label: 'Kontak Email',
    email_placeholder: 'Masukkan email kontak',
    phone_label: 'Hubungi Telepon',
    phone_placeholder: 'Masukkan telepon kontak',
    url_label: 'alamat situs web',
    url_placeholder: 'Masukkan url situs web',
    confirm_text: 'Menyimpan',
  },
  org_message: {
    section_title: 'Pesan Organisasi',
    create: {
      button_text: 'Buat Pesan Baru',
      title: 'Buat Pesan Baru',
      org_label: 'Organisasi',
      org_placeholder: 'Pilih Organisasi',
      recipients_label: 'Penerima',
      recipients_placeholder: 'Tambahkan Penerima',
      msg_title_label: 'Judul Pesan',
      msg_title_placeholder: 'Masukkan Judul Pesan',
      msg_content_label: 'Isi pesan',
      msg_content_placeholder: 'Ketik Isi Pesan',
      image_label: 'Lampirkan Gambar',
      image_placeholder: 'Tidak ada file yang dipilih',
      image_cta: 'Pilih File',
      video_label: 'Lampirkan Video',
      video_placeholder: 'Masukkan tautan video',
      file_label: 'Lampirkan file',
      file_placeholder: 'Tidak ada file yang dipilih',
      file_cta: 'Pilih File',
      send_text: 'Mengirim',
      draft_text: 'Simpan di Draf',
      schedule_text: 'Jadwal',
    },
    details: {
      name_label: 'Nama Organisasi',
      date_label: 'Tanggal & Waktu Terkirim',
      language_label: 'Bahasa',
      status_label: 'Status',
      views_label: 'Tampilan Pengguna',
      heading_label: 'Judul Pesan',
      content_label: 'Isi pesan',
      video_label: 'Video Pesan',
      cta_label: 'Panggilan untuk bertindak',
    },
  },
  org_user: {
    section_title: 'Pengguna Organisasi',
    add_modal: {
      button_text: 'Tambahkan pengguna org baru',
      title: 'Tambahkan pengguna org baru',
      name_label: 'Full Name',
      name_placeholder: 'Masukkan nama',
      name_error: 'Silakan masukkan nama',
      email_label: 'ID email',
      email_placeholder: 'Masukkan id email',
      email_error: 'Harap masukkan email yang valid.',
      role_label: 'Peran pengguna',
      role_placeholder: 'Pilih peran pengguna',
      role_error: 'Pilih peran pengguna',
      confirm_text: 'Tambahkan pengguna org baru',
    },
  },
  org_user_detail: {
    section_title: 'Detail Pengguna Organisasi',
    name_label: 'Nama',
    email_label: 'Surel',
    role_label: 'Peran',
    status_label: 'Status',
    created_label: 'Dibuat di',
    updated_label: 'Diperbarui Pada',
    created_by_label: 'Dibuat oleh',
    last_login_label: 'Login terakhir',
    org_name_label: 'Nama Organisasi',
    permissions_title: 'Informasi Izin',
    edit_modal: {
      button_text: 'Sunting profil',
      title: 'Edit Pengguna',
      name_label: 'Nama lengkap',
      name_placeholder: 'Masukkan nama',
      name_error: 'Silakan masukkan nama',
      email_label: 'ID email',
      email_placeholder: 'Masukkan id email',
      email_error: 'Please input valid email.',
      org_label: 'Organisasi',
      org_placeholder: 'Masuk organisasi',
      org_error: 'Harap berikan organisasi.',
      role_label: 'Peran pengguna',
      role_placeholder: 'Pilih peran pengguna',
      role_error: 'Pilih peran pengguna',
      confirm_text: 'Perbarui Pengguna Sistem',
    },
    password_modal: {
      button_text: 'Setel Ulang Kata Sandi',
      title: 'Setel Ulang Kata Sandi',
      description: 'Apakah Anda yakin ingin menyetel ulang sandi pengguna ini?',
      email_label: 'ID email',
      email_placeholder: 'Masukkan id email',
      email_error: 'Harap masukkan email yang valid.',
      confirm_text: 'Menghapus',
      cancel_text: 'Membatalkan',
      password_not_match: "Kata sandi tidak cocok"
    },
    delete_modal: {
      title: 'Hapus pengguna',
      description: 'Apakah Anda yakin ingin menghapus pengguna ini dari sistem?',
    }
  },
  system_users: {
    section_title: 'Pengguna Sistem',
    add_modal: {
      button_text: 'Tambahkan Pengguna Sistem Baru',
      title: 'Tambahkan Pengguna Sistem Baru',
      name_label: 'Nama lengkap',
      name_placeholder: 'Masukkan nama',
      name_error: 'Silakan masukkan nama pengguna',
      email_label: 'Alamat email',
      email_placeholder: 'Masukkan alamat email',
      email_error: 'Harap masukkan email yang valid.',
      role_label: 'Peran pengguna',
      role_placeholder: 'Pilih peran pengguna',
      role_error: 'Pilih peran pengguna',
      confirm_text: 'Tambahkan Pengguna Org Baru',
    },
  },
  form: {
    loading_label: 'Pemuatan...',
    name_label: 'Nama lengkap',
    name_placeholder: 'Masukkan Nama',
    name_error: 'Silakan masukkan nama',
    email_Label: 'ID Email',
    email_placeholder: 'Masukkan alamat email',
    email_error: 'Silakan masukkan email yang valid',
    contact_email_label: 'Kontak Email',
    contact_email_placeholder: 'Masukkan Email Kontak',
    email_required: 'Silakan masukkan alamat email',
    contact_phone_label: 'Telepon Kontak',
    contact_phone_placeholder: 'Masukkan Telepon Kontak',
    contact_phone_error: 'Silakan masukkan nomor telepon organisasi!',
    reset_text: 'Setel Ulang Kata Sandi',
    save_text: 'Menyimpan',
    cancel_text: 'Membatalkan',
    password_label: 'Masukkan Kata Sandi',
    password_placeholder: 'Masukan kata sandi baru',
    password_not_match: 'Sandi tidak cocok',
    org_name_label: 'Organisasi',
    org_name_placeholder: 'Pilih Organisasi',
    org_name_error: 'Silakan masukkan nama organisasi!',
    org_category_label: 'Kategori Organisasi',
    org_category_placeholder: 'Pilih Kategori Organisasi',
    org_category_error: 'Silakan Pilih Kategori Organisasi',
    org_category_load_error: 'Gagal memuat kategori organisasi',
    org_website_label: 'Alamat situs web',
    org_website_placeholder: 'Masukkan URL Situs Web',
    org_website_error: 'Silakan masukkan situs web organisasi!',
    org_logo_label: 'Logo Organisasi',
    org_desc: 'Deskripsi',
    org_desc_placeholder: 'Ketik konten deskripsi',
    msg_title_label: 'Pesan',
    msg_title_placeholder: 'Masukkan Judul Pesan',
    msg_content_label: 'Isi Pesan',
    msg_content_placeholder: 'Masukkan Isi Pesan',
    org_label: 'Organisasi',
    org_placeholder: 'Pilih Organisasi',
    recipients_label: 'Penerima',
    recipients_placeholder: 'Tambahkan Penerima',
    image_label: 'Lampirkan Gambar',
    image_placeholder: 'Tidak ada file yang dipilih',
    image_cta: 'Pilih File',
    video_label: 'Lampirkan Video',
    video_placeholder: 'Masukkan tautan video',
    file_label: 'Pilih File',
    file_placeholder: 'Tempel tautan/url file',
    file_cta: 'Pilih File',
    send_text: 'Send',
    draft_text: 'Simpan di Draf',
    schedule_text: 'Jadwal',
    role_label: 'Peran',
    role_placeholder: 'Pilih Peran',
    status_active: 'Aktif',
    status_inactive: 'Tidak Aktif',
    status_suspend: 'Menskors',
    status_delete: "Menghapus",
    update_org_user: 'Perbarui Pengguna Organisasi',
    add_language: 'Tambah Bahasa Baru',
    remove_language: 'Hapus Bahasa',
  },
};

export default translationBahasaIndonesia;
