import React, { useEffect } from 'react';
// import Permissions from '../../components/profile-widgets/permissions';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { viewOrgUserAction } from '../../store/actions/orgUsersAction';
import { Skeleton, Alert } from 'antd';
import OrgUserProfileHeader from '../../components/profile-widgets/org-user-profile-header';
import { useTranslation } from 'react-i18next';

function SystemUserDetails() {
  const { currentUserLoading, currentUser, currentUserError } = useSelector(
    (state) => state.orgUsers
  );
  const { profile_uuid } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewOrgUserAction(profile_uuid));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  const { t } = useTranslation();
  let title = t('org_user_detail.section_title');
  let breadcrumbsItem = [
    // { home: false, title: t('left_sidebar.dashboard'), url: '/', last: false },
    {
      home: false,
      title: t('org_user.section_title'),
      url: '/users',
      last: false,
    },
    { title, url: '/', last: true },
  ];

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />

      <div className="w-full mb-4">
        <div className="user-cover w-full h-24 lg:h-40"></div>
        {currentUserLoading && (
          <div className="px-6 pb-6 flex space-x-4">
            <div>
              <Skeleton.Avatar active style={{ width: 200, height: 200 }} className="-mt-16 block" />
            </div>
            <div className="w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 border-b pb-6">
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 border-b pb-6">
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
                <div className="p-2 lg:mt-4">
                  <Skeleton active paragraph={{ rows: 1 }} block />
                </div>
              </div>

              <div className="flex pt-4 space-x-4">
                <Skeleton.Button active style={{ width: "200px" }} />
                <Skeleton.Button active style={{ width: "200px" }} />
              </div>
            </div>
          </div>
        )}
        {currentUser && <OrgUserProfileHeader userDetails={currentUser} />}
        {/* <Permissions /> */}

        {currentUserError && <Alert className="py-5 mt-4" message="Failed to load user details. Retry after sometime." type="error" showIcon={false} />}
      </div>
    </>
  );
}

export default SystemUserDetails;
