import React from 'react';
import SectionTitle from '../../components/dashboard/section-title';
import CustomerTable from './customers-table';
import Breadcrumb from '../../components/breadcrumbs';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function CustomersList() {
  const { t } = useTranslation();
  let title = t('customer_list.section_title');
  let breadcrumbsItem = [
    // { home: false, title: t('left_sidebar.dashboard'), url: '/', last: false },
    { title, url: '/', last: true },
  ];
  const { currentUser } = useSelector((state) => state.auth);

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <CustomerTable organization_uuid={currentUser?.organization} />
      </Card>
    </>
  );
}

export default CustomersList;
