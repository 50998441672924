import * as types from '../actionTypes/org-messages';

// ORG MESSAGES TABLE LISTING
export const listOrgMessagesAction = (payload) => {
  return {
    type: types.LIST_ORG_MESSAGES,
    payload,
  };
};

export const listOrgMessagesSuccessAction = (messages) => {
  return {
    type: types.LIST_ORG_MESSAGES_SUCCESS,
    payload: messages,
  };
};

export const listOrgMessagesFailedAction = (error) => {
  return {
    type: types.LIST_ORG_MESSAGES_FAILED,
    payload: error,
  };
};

// CREATE ORG MESSAGE
export const createOrgMessageAction = (values) => {
  return {
    type: types.CREATE_ORG_MESSAGE,
    payload: values,
  };
};
export const createOrgMessageSuccessAction = (user) => {
  return {
    type: types.CREATE_ORG_MESSAGE_SUCCESS,
    payload: user,
  };
};
export const createOrgMessageFailAction = (error) => {
  return {
    type: types.CREATE_ORG_MESSAGE_FAIL,
    payload: error,
  };
};

// VIEW ORG MESSAGE
export const viewOrgMessageAction = (uuid) => {
  return {
    type: types.VIEW_ORG_MESSAGE,
    payload: uuid,
  };
};
export const viewOrgMessageSuccessAction = (message) => {
  return {
    type: types.VIEW_ORG_MESSAGE_SUCCESS,
    payload: message,
  };
};
export const viewOrgMessageFailAction = (error) => {
  return {
    type: types.VIEW_ORG_MESSAGE_FAIL,
    payload: error,
  };
};

//EDIT ORG MESSAGE
export const editOrgMessageAction = (message_uuid, values) => {
  return {
    type: types.EDIT_ORG_MESSAGE,
    payload: { message_uuid, values },
  };
};
export const editOrgMessageSuccessAction = (message) => {
  return {
    type: types.EDIT_ORG_MESSAGE_SUCCESS,
    payload: message,
  };
};
export const editOrgMessageFailAction = (error) => {
  return {
    type: types.EDIT_ORG_MESSAGE_FAIL,
    payload: error,
  };
};