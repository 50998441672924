export const LIST_ORG_MESSAGES = 'LIST_ORG_MESSAGES';
export const LIST_ORG_MESSAGES_SUCCESS = 'LIST_ORG_MESSAGES_SUCCESS';
export const LIST_ORG_MESSAGES_FAILED = 'LIST_ORG_MESSAGES_FAILED';

export const CREATE_ORG_MESSAGE = 'CREATE_ORG_MESSAGE';
export const CREATE_ORG_MESSAGE_SUCCESS = 'CREATE_ORG_MESSAGE_SUCCESS';
export const CREATE_ORG_MESSAGE_FAIL = 'CREATE_ORG_MESSAGE_FAIL';

export const VIEW_ORG_MESSAGE = 'VIEW_ORG_MESSAGE';
export const VIEW_ORG_MESSAGE_SUCCESS = 'VIEW_ORG_MESSAGE_SUCCESS';
export const VIEW_ORG_MESSAGE_FAIL = 'VIEW_ORG_MESSAGE_FAIL';

export const EDIT_ORG_MESSAGE = 'EDIT_ORG_MESSAGE';
export const EDIT_ORG_MESSAGE_SUCCESS = 'EDIT_ORG_MESSAGE_SUCCESS';
export const EDIT_ORG_MESSAGE_FAIL = 'EDIT_ORG_MESSAGE_FAIL';
