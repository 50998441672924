import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumbs';
import SectionTitle from '../../components/dashboard/section-title';
import {
  Card,
  Modal,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  Skeleton,
  Space,
  message,
} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';
import { viewOrganizationSubscriberAction } from '../../store/actions/organizationActions';
import {
  viewOrgMessageAction,
  editOrgMessageAction,
} from '../../store/actions/orgMessageActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import useDebounce from '../../hooks/useDebounce';
import { useParams } from 'react-router-dom';
import { fileUploadHandler, imageUploadHandler } from '../../utils/upload';
import { BiFile } from 'react-icons/bi';
import {
  resetFileUploadAction,
  resetImageUploadAction,
} from '../../store/actions/uploadAction';
import { isEmpty } from '../../utils/generic';
// import PDFViewer from '../../components/pdf-viewer';

function EditOrgMessage() {
  const { t } = useTranslation();
  let title = 'Edit Message';
  const { message_uuid } = useParams();
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = moment.tz(zone).format('Z');
  let breadcrumbsItem = [
    {
      home: true,
      title: 'Organisation Panel',
      url: '/',
      last: false,
    },
    {
      home: false,
      title: t('org_message.section_title'),
      url: '/organisations/messages',
      last: false,
    },
    { home: false, title: 'Edit', url: '/', last: true },
  ];
  const { Option } = Select;
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = [
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'link',
    'image',
    'video',
  ];
  const [searchSubscribers, setSearchSubscribers] = useState('');
  const debouncedSubsSearchValue = useDebounce(searchSubscribers, 500);

  let orgSubscribersInitialValue = {
    organization_uuid: '',
    page: 1,
    search: '',
    ordering: '',
    page_size: '',
    created_at_after: '',
    created_at_before: '',
  };

  const [scheduleMessageModal, setScheduleMessageModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const dispatch = useDispatch();

  const { uploadReducer, organization, orgMessages } = useSelector(
    (state) => ({
      uploadReducer: state.uploadReducer,
      organization: state.organization,
      orgMessages: state.orgMessages,
    }),
    shallowEqual
  );

  const { uploadFile, uploadFileLoading, uploadImage, uploadImageLoading } = uploadReducer;
  const { orgSubscribers, orgSubscribersLoading } = organization;
  const { currentMessage, currentMessageLoading } = orgMessages;
  const [messageTitle, setMessageTitle] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [wordLimitWarning, setWordLimitWarning] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [currentVideo, setCurrentVideo] = useState('');

  const [createMessageFormData, setCreateMessageFormData] = useState({
    images: [],
    videos: [],
    files: [],
    recipient: { include: [] },
  });
  const currentMessageInitialFormValue = {
    organization: currentMessage?.organization,
    title: currentMessage?.title,
    body: currentMessage?.body,
    videos: currentVideo || currentMessage?.videos?.[0],
  };
  let recipients = createMessageFormData?.recipient?.include;

  const filtered_recipients = {
    include: recipients?.filter((item) => item !== 'all'),
  };

  const validateMessageForm = () => {
    let title = createMessageFormData.title;
    let body = createMessageFormData.body;
    let organisation = createMessageFormData.organisation;
    let recipient = createMessageFormData.recipient;
    if (
      title === '' ||
      body === '' ||
      organisation === '' ||
      recipient.include.length === 0
    ) {
      message.error('Please provide all required fields');
      setScheduleMessageModal(false);
      return false;
    } else {
      return true;
    }
  };

  const editMessageFormData = {
    ...createMessageFormData,
    title: messageTitle || currentMessage?.title,
    body: messageBody || currentMessage?.body,
    recipient: filtered_recipients,
    files: [uploadFile || currentMessage?.files?.[0]],
    images: [uploadImage || currentMessage?.images?.[0]],
    videos: [currentVideo || currentMessage?.videos?.[0]],
    organization: currentMessage?.org_uuid,
  };

  const reactQuillHandler = (content, delta, source, editor) => {
    const slicedContent = content.trim();
    const characterCount = editor.getText().trim().length;
    const cursorPosition = editor?.getSelection()?.index;
    !isEmpty(cursorPosition) ? setWordCount(cursorPosition) : setWordCount('-');
    if (characterCount <= 1000) {
      setWordLimitWarning(false);
      setMessageBody(slicedContent);
    } else {
      setWordLimitWarning(true);
    }
  };
  const sendMessageFormHandler = (values) => {
    validateMessageForm();
    if (buttonClicked === 'send') {
      dispatch(
        editOrgMessageAction(message_uuid, {
          ...editMessageFormData,
          status: 1,
        })
      );
    }
    if (buttonClicked === 'draft') {
      dispatch(
        editOrgMessageAction(message_uuid, {
          ...editMessageFormData,
          status: 2,
        })
      );
    }
  };
  const scheduleMessageFormHandler = ({ date, time }) => {
    const validated = validateMessageForm();
    const schedule_dateTime = `${moment(date._d).format('YYYY-MM-DD')} ${moment(
      time._d
    ).format('HH:mm:ss')}.00${timezone}`;
    if (validated) {
      setScheduleMessageModal(false);
      dispatch(
        editOrgMessageAction(message_uuid, {
          ...editMessageFormData,
          status: 3,
          schedule_dateTime,
        })
      );
    }
  };
  const listSubscriberSearchHandler = (value) => {
    setSearchSubscribers(value);
  };
  useEffect(() => {
    dispatch(viewOrgMessageAction(message_uuid));
    dispatch(resetImageUploadAction());
    dispatch(resetFileUploadAction());
    if (debouncedSubsSearchValue !== '') {
      const timer = setTimeout(() => {
        dispatch(
          viewOrganizationSubscriberAction({
            ...orgSubscribersInitialValue,
            search: debouncedSubsSearchValue,
            organization_uuid: currentMessage?.org_uuid,
          })
        );
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    } else {
      currentMessage?.org_uuid &&
        dispatch(
          viewOrganizationSubscriberAction({
            ...orgSubscribersInitialValue,
            organization_uuid: currentMessage?.org_uuid,
          })
        );
    }
  }, [debouncedSubsSearchValue]); // eslint-disable-line

  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
        <div className="w-full lg:w-2/3">
          {currentMessageLoading ? (
            <div className="px-6 pb-6">
              <Skeleton active paragraph={{ rows: 12 }} />
            </div>
          ) : (
            <Card>
              <Form
                name="editMessage"
                autoComplete="off"
                layout="vertical"
                onFinish={sendMessageFormHandler}
                initialValues={currentMessageInitialFormValue}
              >
                <Form.Item
                  className="mb-6"
                  name="organization"
                  label={t('org_message.create.org_label')}
                >
                  <Input size="large" disabled />
                </Form.Item>

                <Form.Item
                  className="mb-6"
                  name="recipient"
                  label={t('org_message.create.recipients_label')}
                  rules={[
                    {
                      required: true,
                      message: 'Please select recipient!',
                    },
                  ]}
                >
                  <Select
                    size="large"
                    labelInValue
                    filterOption={false}
                    showSearch
                    onSearch={listSubscriberSearchHandler}
                    placeholder={t('org_message.create.recipients_placeholder')}
                    mode="multiple"
                    maxTagCount={4}
                    defaultActiveFirstOption
                    // disabled={selectedOrganisationUUID === null}
                    onClick={() =>
                      !(orgSubscribers?.count > 0) &&
                      dispatch(
                        viewOrganizationSubscriberAction({
                          ...orgSubscribersInitialValue,
                          organization_uuid: currentMessage?.org_uuid,
                        })
                      )
                    }
                    onSelect={({ value }) => console.log('onSelect', value)}
                    onChange={(values) => {
                      setCreateMessageFormData({
                        ...createMessageFormData,
                        recipient: {
                          include: values?.map((item) => item?.value),
                        },
                      });
                    }}
                    onDeselect={({ value }) => {
                      setCreateMessageFormData({
                        ...createMessageFormData,
                        recipient: {
                          include: recipients?.filter((item) => item !== value),
                        },
                      });
                    }}
                  >
                    {orgSubscribersLoading ? (
                      <Option>Loading...</Option>
                    ) : (
                      <>
                        <Option
                          disabled={
                            recipients?.length >= 1 &&
                              !recipients?.includes(
                                'all' ||
                                typeof createMessageFormData?.recipient
                                  ?.include[0] === 'number'
                              )
                              ? true
                              : false
                          }
                          value="all"
                        >
                          {recipients?.length > 0 &&
                            !recipients?.includes('all') ? (
                            <del>All Recipient</del>
                          ) : (
                            'All Recipient'
                          )}
                        </Option>
                        {orgSubscribers?.results.length > 0 ? (
                          orgSubscribers?.results?.slice(0, 5)?.map((item) => (
                            <Option
                              disabled={
                                recipients?.length === 1 &&
                                recipients?.includes('all')
                              }
                              key={item?.subscriber_id}
                              value={item?.subscriber_id}
                            >
                              {item?.name}
                            </Option>
                          ))
                        ) : (
                          <Option disabled value="none">
                            No subscriber found
                          </Option>
                        )}
                      </>
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  className="mb-6"
                  name="title"
                  label={t('org_message.create.msg_title_label')}
                  rules={[
                    {
                      required: true,
                      message: 'Please provide message title!',
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t('org_message.create.msg_title_placeholder')}
                    onChange={(e) => setMessageTitle(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  className="mb-6"
                  name="body"
                  label={t('org_message.create.msg_content_label')}
                  tooltip="Max 1000 characters"
                  rules={[
                    {
                      required: true,
                      message: 'Please write message body',
                    },
                  ]}
                >
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder={t(
                      'org_message.create.msg_content_placeholder'
                    )}
                    // onChange={(e) => setMessageBody(e)}
                    onChange={reactQuillHandler}
                  />
                </Form.Item>
                <div className="flex justify-between items-center -mt-6 mb-2">
                  <div className="text-red-500 text-sm">
                    {wordLimitWarning && 'Character limit exceeded!'}
                  </div>
                  <div className="text-grey-500 text-sm">
                    <span
                      className={`${wordLimitWarning ? 'text-red-500' : ''}`}
                    >
                      {wordCount}
                    </span>
                    /1000 characters
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6 file-uploader"
                      name="images"
                      label="Edit Images"
                    >
                      <Input
                        size="large"
                        placeholder={t('org_message.create.image_placeholder')}
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={imageUploadHandler}
                      />
                      {uploadImageLoading && 'Uploading...'}
                      {uploadImage ? (
                        <img
                          src={uploadImage}
                          alt=""
                          className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
                        />
                      ) : !isEmpty(currentMessage?.images?.[0]) ? (
                        <img
                          src={currentMessage?.images?.[0]}
                          alt=""
                          className="w-32 h-auto p-2 mt-3 border border-grey-300 rounded"
                        />
                      ) : null}
                    </Form.Item>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6 file-uploader"
                      name="files"
                      label="Edit File"
                    >
                      <Input
                        size="large"
                        placeholder={t('org_message.create.image_placeholder')}
                        type="file"
                        accept="application/pdf"
                        onChange={fileUploadHandler}
                      />
                      {uploadFileLoading && 'Uploading...'}
                      {uploadFile ? (
                        <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                          <BiFile className="text-blue-link text-lg" />
                          <a
                            className="text-sm font-bold text-blue-link pl-2 truncate"
                            href={uploadFile}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            File Attachment
                          </a>
                        </div>
                      ) : !isEmpty(currentMessage?.files?.[0]) ? (
                        <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                          <BiFile className="text-blue-link text-lg" />
                          <a
                            className="text-sm font-bold text-blue-link pl-2 truncate"
                            href={`${currentMessage?.files?.[0]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            File Attachment
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <Form.Item
                      className="mb-6"
                      name="videos"
                      label="Edit Video URL"
                      rules={[
                        {
                          type: 'url',
                          message: 'This field must be a valid url.',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t('org_message.create.video_placeholder')}
                        onChange={(e) => setCurrentVideo(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item className="mb-3">
                  <div className="flex flex-col lg:flex-row lg:justify-between lg:space-x-8 text-center mt-4">
                    <Button
                      type="success"
                      className="w-full lg:w-1/3 mb-4 lg:mb-0"
                      size="large"
                      // onClick={sendMessageFormHandler}
                      onClick={() => setButtonClicked('send')}
                      htmlType="submit"
                    >
                      {t('org_message.create.send_text')}
                    </Button>
                    <Button
                      type="warning"
                      className="w-full lg:w-1/3 mb-4 lg:mb-0"
                      size="large"
                      htmlType="submit"
                      onClick={() => setButtonClicked('draft')}
                    >
                      {t('org_message.create.draft_text')}
                    </Button>
                    <Button
                      type="primary"
                      className="w-full lg:w-1/3 mb-4 lg:mb-0"
                      size="large"
                      htmlType="submit"
                      onClick={() => setScheduleMessageModal(true)}
                    >
                      {t('org_message.create.schedule_text')}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          )}
        </div>
        <div className="flex justify-center w-full lg:w-1/3">
          <div className="mockup-phone">
            <div className="message">
              {currentMessageLoading ? (
                <Space>
                  <Skeleton.Image className="mr-2 mb-1" />
                  <Skeleton.Input
                    style={{ width: 200 }}
                    active={currentMessageLoading}
                  />
                </Space>
              ) : (
                <div className="message-header">
                  <div className="flex items-center mb-2">
                    {currentMessage ? (
                      <div className="logo">
                        <img src={currentMessage?.org_logo} alt="logo" />
                      </div>
                    ) : (
                      <Skeleton.Image className="mr-2 mb-1" />
                    )}

                    <div className="message-sender">
                      <div className="font-bold text-base mb-2 leading-none">
                        {currentMessageLoading
                          ? 'Organisation Name'
                          : currentMessage?.organization}
                      </div>
                      <div className="text-xs text-grey-600">
                        {createMessageFormData?.organization
                          ? new Date().toLocaleString() + ''
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="message-header-title text-base font-bold leading-5 border-b border-grey-300 pb-4 mb-4">
                    {currentMessageLoading
                      ? 'Message Title'
                      : currentMessage?.title}
                  </div>
                </div>
              )}
              <div className="message-content">
                <div
                  className="pb-4"
                  dangerouslySetInnerHTML={{
                    __html: `${currentMessageLoading
                      ? 'Message Body'
                      : currentMessage?.body
                      }`,
                  }}
                ></div>
                {uploadImage ? (
                  <img
                    src={uploadImage}
                    alt=""
                    className="w-full h-auto p-2 mt-3 border border-grey-300 mx-auto rounded"
                  />
                ) : !isEmpty(currentMessage?.images?.[0]) ? (
                  <img
                    src={currentMessage?.images?.[0]}
                    alt=""
                    className="w-full h-auto p-2 mt-3 border border-grey-300 mx-auto rounded"
                  />
                ) : null}
              </div>
              <div className="message-footer px-4 pb-5">
                {currentMessage?.videos?.[0] || currentVideo ? (
                  <ReactPlayer
                    className="youtube-embed"
                    width="100%"
                    height="auto"
                    url={
                      currentVideo ? currentVideo : currentMessage?.videos?.[0]
                    }
                    config={{ youtube: { playerVars: { showinfo: 1 } } }}
                  />
                ) : (
                  ''
                )}
                {uploadFile ? (
                  <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                    <BiFile className="text-blue-link text-lg" />
                    <a
                      className="text-sm font-bold text-blue-link pl-2 truncate"
                      href={uploadFile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File Attachment
                    </a>
                  </div>
                ) : !isEmpty(currentMessage?.files?.[0]) ? (
                  <div className="flex items-center px-4 py-2 bg-blue-50 rounded my-4 border border-blue-500">
                    <BiFile className="text-blue-link text-lg" />
                    <a
                      className="text-sm font-bold text-blue-link pl-2 truncate"
                      href={`${currentMessage?.files?.[0]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      File Attachment
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PDFViewer file="https://anak-web-dashboard.s3.amazonaws.com/files/3b900506-edff-4038-8652-d44c5bd28d09.pdf" /> */}
      <Modal
        visible={scheduleMessageModal}
        onOk={() => setScheduleMessageModal(false)}
        onCancel={() => setScheduleMessageModal(!scheduleMessageModal)}
        footer={false}
        centered
      >
        <div className="py-4">
          <div className="text-xl font-bold mb-4 text-center">
            Schedule Message
          </div>
          <Form
            name="createMessage"
            autoComplete="off"
            layout="vertical"
            onFinish={scheduleMessageFormHandler}
          >
            <Form.Item
              className="mb-6"
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: 'Please select date!',
                },
              ]}
            >
              <DatePicker
                className="w-full"
                size="large"
                format="YYYY-MM-DD"
                disabledDate={(current) =>
                  current && current < moment().startOf('day')
                }
              />
            </Form.Item>
            <Form.Item
              className="mb-8"
              name="time"
              label={`Time (Timezone: ${zone}/${timezone})`}
              format="HH:mm:ss"
              rules={[
                {
                  required: true,
                  message: 'Please select time!',
                },
              ]}
            >
              <TimePicker
                className="w-full"
                size="large"
                disabledTime={(current) =>
                  current && current < moment().startOf('minute')
                }
              />
            </Form.Item>
            <Form.Item>
              <div className="flex justify-between">
                <Button
                  size="large"
                  type="success"
                  className="w-64 mr-4"
                  htmlType="submit"
                  onClick={() => setButtonClicked('schedule')}
                >
                  Schedule Message
                </Button>
                <Button
                  size="large"
                  onClick={() => setScheduleMessageModal(false)}
                  className="w-64"
                >
                  Close
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default EditOrgMessage;
