import axiosClient from './config';

export const listOrgCategoryService = async ({
  page,
  search,
  ordering,
  page_size,
  created_at_after,
  created_at_before,
}) => {
  return await axiosClient.get(
    `/organisation-categories/?page=${page}&search=${search}&ordering=${ordering}&page_size=${page_size}&created_at_after=${created_at_after}&created_at_before=${created_at_before}`
  );
};

export const getOrgCategoryService = async (OrgCategoryUuid) => {
  return await axiosClient.get(`/organisation-categories/${OrgCategoryUuid}/`);
};
export const createOrgCategoryService = async (name) => {
  return await axiosClient.post('/organisation-categories/', {
    name,
  });
};
export const editOrgCategoryService = async (uuid, name) => {
  return await axiosClient.patch(`/organisation-categories/${uuid}/`, {
    name,
  });
};
export const deleteOrgCategoryService = async (category_uuid) => {
  return await axiosClient.delete(`/organisation-categories/${category_uuid}/`);
};
