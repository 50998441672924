import * as types from "../actionTypes";

const initialState = {
  customerList: null,
  listCustomerError: null,
  listCustomerSuccess: null,
  listCustomerLoading: false,
  currentUserLoading: false,
  currentUserSuccess: false,
  currentUserError: null,
  currentUser: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_CUSTOMER:
      return {
        ...state,
        listCustomerError: null,
        listCustomerSuccess: null,
        listCustomerLoading: true,
      };

    case types.LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerList: action.payload,
        listCustomerLoading: false,
        listCustomerError: null,
        listCustomerSuccess: true,
      };

    case types.LIST_CUSTOMER_FAILED:
      return {
        ...state,
        customerList: null,
        listCustomerLoading: false,
        listCustomerError: action.payload,
      };
    case types.VIEW_CUSTOMER_DETAILS:
      return {
        ...state,
        currentCustomerLoading: true,
        currentCustomerSuccess: false,
        currentCustomerError: null,
        currentCustomer: null,
      };
    case types.VIEW_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        currentCustomerLoading: false,
        currentCustomerSuccess: true,
        currentCustomerError: null,
        currentCustomer: action.payload,
      };
    case types.VIEW_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        currentCustomerLoading: false,
        currentCustomerSuccess: false,
        currentCustomerError: action.payload,
        currentCustomer: null,
      };

    default:
      return state;
  }
};

export default customerReducer;
