import React from 'react';
import { BiUser, BiCheck, BiX } from 'react-icons/bi';
// import { LoadingOutlined } from '@ant-design/icons';
// import { useParams } from 'react-router-dom';
import { Avatar } from 'antd';
// import {  } from 'react-icons/bi';
// import { useSelector, shallowEqual } from 'react-redux';
// import { forgotPasswordRequestAction } from '../../store/actions/authActions';
// import { editOrgUserAction } from '../../store/actions/orgUsersAction';
import { createdByFormat } from '../../utils/roleFormat';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { replaceUnderscore } from '../../utils/string-helpers.js'

const OrgUserProfileHeader = ({ userDetails }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-start w-full user-widget-2">
      <div className="w-full bottom-section md:px-8">
        <div className="flex flex-col sm:flex-row">
          <div className="flex-shrink-0 w-full sm:w-1/5">
            {userDetails?.profile_image ? (
              <span
                className="flex justify-center items-center -mt-16 md:h-48 w-24 md:w-48 p-2 border-2 border-white rounded-full bg-white bg-cover bg-center"
                style={{
                  backgroundImage: `url(${userDetails?.profile_image})`,
                  boxShadow: '0 0px 24px -5px rgba(0, 0, 0, 0.5)'
                }}
              />
            ) : (
              <Avatar
                className=" flex justify-center items-center w-24 md:w-48 shadow-xl mx-auto -mt-16 border-2 border-white"
                style={{ verticalAlign: 'middle' }}
                size={{ xs: 80, sm: 80, md: 120, lg: 160, xl: 210, xxl: 240 }}
                icon={<BiUser />}
              />
            )}
          </div>
          <div className="sm:ml-4 flex flex-col w-full sm:w-4/5">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 border-b">
              <div className="p-2 lg:mt-4">
                <div className="text-xs">{t('org_user_detail.name_label')}</div>
                <div className="text-base font-bold truncate">
                  {userDetails?.name}
                </div>
              </div>
              <div className="p-2 lg:mt-4">
                <div className="text-xs">{t('org_user_detail.email_label')}</div>
                <div className="text-base font-bold truncate" title={userDetails?.user_detail.email}>
                  {userDetails?.user_detail.email}
                </div>
              </div>
              <div className="p-2 lg:mt-4">
                <div className="text-xs">{t('org_user_detail.role_label')}</div>
                <div className="text-base font-bold truncate capitalize">
                  {replaceUnderscore(userDetails?.user_detail?.role)}
                </div>
              </div>
              <div className="p-2 lg:mt-4">
                <div className="text-xs">{t('org_user_detail.status_label')}</div>
                <div className="text-base font-bold truncate flex items-center">
                  <div className="flex items-center" >
                    <div className="text-base font-bold flex items-center">
                      {userDetails?.user_detail?.is_active ? (
                        <>
                          <div className="rounded-full bg-green-anak">
                            <BiCheck className="h-4 w-4 text-white " />
                          </div>
                          <span className="text-green-anak font-bold text-base ml-2 capitalize">
                            {t('table.status.active')}
                          </span>
                        </>
                      ) : (
                        <>
                          <div className="rounded-full bg-red-anak">
                            <BiX className="h-4 w-4 text-white " />
                          </div>
                          <span className="text-red-anak font-bold text-base ml-2 capitalize">
                            {t('table.status.inactive')}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2 border-b">
              <div className="p-2 mt-4">
                <div className="text-xs">{t('table.thead.user_created_at')}</div>
                <div className="text-base font-bold truncate">
                  <Moment format="DD MMM YYYY, HH:MM" date={userDetails?.createdAt} />
                </div >
              </div >
              <div className="p-2 mt-4">
                <div className="text-xs">{t('table.thead.user_updated_at')}</div>
                <div className="text-base font-bold truncate">
                  <Moment format="DD MMM YYYY, HH:MM" date={userDetails?.modified_at} />
                </div >
              </div >
              <div className="p-2 mt-4">
                <div className="text-xs">{t('table.thead.user_created_by')}</div>
                <div className="text-base font-bold truncate">
                  {createdByFormat(userDetails?.created_by)}
                </div >
              </div >
              <div className="p-2 mt-4">
                <div className="text-xs">{t('table.thead.user_last_login')}</div>
                <div className="text-base font-bold truncate">
                  <Moment format="DD MMM YYYY, HH:MM" date={userDetails?.lastLogin} />
                </div >
              </div >
            </div >

            <div className="col-span-4 p-2 border-b mt-4">
              <div className="text-xs"> {t('table.thead.msg_org_name')}</div>
              <div className="text-base font-bold truncate max-w-full">
                {userDetails?.organization}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default OrgUserProfileHeader;
