import React from 'react';
import { BiCheck, BiUser, BiX } from 'react-icons/bi';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import dateFormat from '../../utils/dateFormat';
// import { getKycStatus } from '../status/kyc';

const CustomerProfileHeader = ({ userDetails }) => {
  const { t } = useTranslation();

  const { data } = userDetails;
  const {
    name,
    mobile,
    profile_image_url,
    is_active,
    subscribed_at,
    unsubscribed_at,
  } = data;

  return (
    <div className="flex flex-col items-center justify-start w-full overflow-hidden user-widget-2">
      <div className="user-cover w-full h-24 lg:h-40"></div>
      <div className="w-full bottom-section md:px-8">
        <div className="flex flex-col sm:flex-row">
          <div className="flex-shrink-0 flex flex-col w-full sm:w-1/5">
            {profile_image_url ? (
              <img
                src={profile_image_url}
                alt={name}
                className="rounded-full w-24 md:w-48 shadow-xl mx-auto -mt-16 border-2 border-white"
              />
            ) : (
              <Avatar
                className=" flex justify-center items-center w-24 md:w-48 shadow-xl mx-auto -mt-16 border-2 border-white rounded-full"
                style={{ verticalAlign: 'middle' }}
                size={{ xs: 80, sm: 80, md: 120, lg: 160, xl: 180 }}
                icon={<BiUser />}
              />
            )}
          </div>
          <div className="sm:ml-4 flex flex-col w-full sm:w-4/5 mb-1">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 p-2 pb-4 border-b">
              <div className="mt-4">
                <div className="text-xs mb-1">{t('table.thead.user_name')}</div>
                <div className="text-base font-bold">{name || '-'}</div>
              </div>
              <div className="mt-4">
                <div className="text-xs mb-1">
                  {t('table.thead.user_phone')}
                </div>
                <div className="text-base font-bold">{mobile || '-'}</div>
              </div>
              <div className="mt-4">
                <div className="text-xs mb-1">
                  {t('table.thead.subscriptionStatus')}
                </div>
                <div className="text-base font-bold flex items-center">
                  {is_active ? (
                    <>
                      <div className="rounded-full bg-green-anak">
                        <BiCheck className="h-4 w-4 text-white " />
                      </div>
                      <span className="text-green-anak font-bold text-base ml-2 capitalize">
                        {t('table.thead.subscribed')}
                      </span>
                    </>
                  ) : (
                    <>
                      <div className="rounded-full bg-red-anak">
                        <BiX className="h-4 w-4 text-white " />
                      </div>
                      <span className="text-red-anak font-bold text-base ml-2 capitalize">
                        {t('table.thead.unsubscribed')}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 p-2 pb-4 border-b">
              <div className="mt-4">
                <div className="text-xs mb-1">
                  {t('table.thead.subscribed_on')}
                </div>
                <div className="text-base font-bold">
                  {dateFormat(subscribed_at)}
                </div>
              </div>
              <div className="mt-4">
                <div className="text-xs mb-1">
                  {t('table.thead.unsubscribed_on')}
                </div>
                <div className="text-base font-bold">
                  {dateFormat(unsubscribed_at)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerProfileHeader;
