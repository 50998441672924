import * as types from '../actionTypes/org-messages';

const initialState = {
  orgMessagesList: null,
  orgMessagesListLoading: false,
  orgMessagesListError: null,
  currentMessage: null,
  currentMessageLoading: false,
  currentMessageError: null,
  createMessageLoading: false,
  createMessageError: null,
};

const orgMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_ORG_MESSAGES:
      return {
        ...state,
        orgMessagesListLoading: true,
      };
    case types.LIST_ORG_MESSAGES_SUCCESS:
      return {
        ...state,
        orgMessagesList: action.payload,
        orgMessagesListLoading: false,
      };
    case types.LIST_ORG_MESSAGES_FAILED:
      return {
        ...state,
        orgMessagesListLoading: false,
        orgMessagesListError: action.payload,
      };
    case types.VIEW_ORG_MESSAGE:
      return {
        ...state,
        currentMessageLoading: true,
      };
    case types.VIEW_ORG_MESSAGE_SUCCESS:
      return {
        ...state,
        currentMessage: action.payload,
        currentMessageLoading: false,
      };
    case types.VIEW_ORG_MESSAGE_FAIL:
      return {
        ...state,
        currentMessageLoading: false,
        currentMessageError: action.payload,
      };
    case types.CREATE_ORG_MESSAGE:
      return {
        ...state,
        createMessageLoading: true,
      };
    case types.CREATE_ORG_MESSAGE_SUCCESS:
      return {
        ...state,
        createMessageLoading: false,
      };

    case types.CREATE_ORG_MESSAGE_FAIL:
      return {
        createMessageError: action.payload,
      };
    case types.EDIT_ORG_MESSAGE:
      return {
        ...state,
        currentMessageLoading: true,
      };
    case types.EDIT_ORG_MESSAGE_SUCCESS:
      return {
        ...state,
        currentMessage: action.payload,
        currentMessageLoading: false,
      };
    case types.EDIT_ORG_MESSAGE_FAIL:
      return {
        ...state,
        currentMessageLoading: false,
        currentMessageError: action.payload,
      };
    default:
      return state;
  }
};

export default orgMessagesReducer;