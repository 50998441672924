import { put, all, takeLatest, call } from 'redux-saga/effects';
import * as types from '../store/actionTypes/org-category';
import { notification } from 'antd';
import {
  createOrgCategoryService,
  deleteOrgCategoryService,
  editOrgCategoryService,
  getOrgCategoryService,
  listOrgCategoryService,
} from '../services/orgCategoryService';
import {
  createOrgCategoryFailAction,
  createOrgCategorySuccessAction,
  deleteOrgCategoryFailAction,
  deleteOrgCategorySuccessAction,
  editOrgCategoryFailAction,
  editOrgCategorySuccessAction,
  listOrgCategoryFailAction,
  listOrgCategorySuccessAction,
  viewOrgCategoryFailAction,
  viewOrgCategorySuccessAction,
} from '../store/actions/orgCategoryActions';
// import history from '../utils/history';

//Worker
export function* listOrgCategory({ payload }) {
  try {
    const list = yield listOrgCategoryService(payload);
    yield put(listOrgCategorySuccessAction(list.data.data));
  } catch (error) {
    yield put(listOrgCategoryFailAction(error));
  }
}
export function* getOrgCategoryDetails({ payload: OrgCategoryUuid }) {
  try {
    const details = yield getOrgCategoryService(OrgCategoryUuid);
    yield put(viewOrgCategorySuccessAction(details.data.data));
  } catch (error) {
    yield put(viewOrgCategoryFailAction(error));
  }
}
export function* createOrgCategory({ payload: category_name }) {
  try {
    const create = yield createOrgCategoryService(category_name);
    yield put(createOrgCategorySuccessAction(create));
    notification['success']({
      message: `"${category_name}" category created successfully.`,
      description: 'Success description',
    });
    yield call(listOrgCategory);
  } catch (error) {
    yield put(createOrgCategoryFailAction(error));
    notification['error']({
      message: 'Failed to create category.',
      description: 'Please retry or contact administration.',
    });
  }
}
export function* editOrgCategory({
  payload: { category_uuid, category_name },
}) {
  try {
    const edit = yield editOrgCategoryService(category_uuid, category_name);
    yield put(editOrgCategorySuccessAction(edit.data.data));
    notification['success']({
      message: `Category updated successfully.`,
      description: 'Success description',
    });
    // history.push('/organisations/categories');
  } catch (error) {
    yield put(editOrgCategoryFailAction(error));
    notification['error']({
      message: 'Failed to update category.',
      description: 'Please retry or contact administration.',
    });
  }
}
export function* deleteOrgCategory({ payload: category_uuid }) {
  try {
    yield deleteOrgCategoryService(category_uuid);
    yield put(deleteOrgCategorySuccessAction());
    notification['success']({
      message: `Category deleted successfully.`,
      description: 'Success description',
    });
  } catch (error) {
    yield put(deleteOrgCategoryFailAction(error));
    notification['error']({
      message: 'Failed to delete category.',
      description: 'Please retry or contact administration.',
    });
  }
}

//Watcher
export function* onListOrgCategoryRequest() {
  yield takeLatest(types.LIST_ORG_CATEGORIES, listOrgCategory);
}
export function* onGetOrgCategoryDetails() {
  yield takeLatest(types.VIEW_ORG_CATEGORY, getOrgCategoryDetails);
}
export function* onCreateOrgCategory() {
  yield takeLatest(types.CREATE_ORG_CATEGORY, createOrgCategory);
}
export function* onEditOrgCategory() {
  yield takeLatest(types.EDIT_ORG_CATEGORY, editOrgCategory);
}
export function* onDeleteOrgCategory() {
  yield takeLatest(types.DELETE_ORG_CATEGORY, deleteOrgCategory);
}

export function* orgCategorySaga() {
  yield all([
    onListOrgCategoryRequest(),
    onGetOrgCategoryDetails(),
    onCreateOrgCategory(),
    onEditOrgCategory(),
    onDeleteOrgCategory(),
  ]);
}
