export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export const UPDATE_USER_NAME_SUCCESS = 'UPDATE_USER_NAME_SUCCESS';
export const UPDATE_USER_NAME_FAILURE = 'UPDATE_USER_NAME_FAILURE';

export const EMAIL_CHANGE_REQUEST = 'EMAIL_CHANGE_REQUEST';
export const EMAIL_CHANGE_REQUEST_SUCCESS = 'EMAIL_CHANGE_REQUEST_SUCCESS';
export const EMAIL_CHANGE_REQUEST_FAILURE = 'EMAIL_CHANGE_REQUEST_FAILURE';

export const UPLOAD_USER_PROFILE_IMAGE = 'UPLOAD_USER_PROFILE_IMAGE';
export const UPLOAD_USER_PROFILE_IMAGE_SUCCESS = 'UPLOAD_USER_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_USER_PROFILE_IMAGE_FAILURE = 'UPLOAD_USER_PROFILE_IMAGE_FAILURE';

export const DELETE_USER_PROFILE_IMAGE = 'DELETE_USER_PROFILE_IMAGE';
export const DELETE_USER_PROFILE_IMAGE_SUCCESS = 'DELETE_USER_PROFILE_IMAGE_SUCCESS';
export const DELETE_USER_PROFILE_IMAGE_FAILURE = 'DELETE_USER_PROFILE_IMAGE_FAILURE';