import * as types from '../actionTypes/organizations';

// organization LISTING
export const listOrganizationAction = (tableParams) => {
  return {
    type: types.LIST_organizationS,
    payload: tableParams,
  };
};
export const listOrganizationSuccessAction = (orgs) => {
  return {
    type: types.LIST_organizationS_SUCCESS,
    payload: orgs,
  };
};
export const listOrganizationFailAction = (error) => {
  return {
    type: types.LIST_organizationS_FAILED,
    payload: error,
  };
};

// CREATE organization
export const createOrganizationAction = (values) => {
  return {
    type: types.CREATE_organization,
    payload: values,
  };
};
export const createOrganizationSuccessAction = (org) => {
  return {
    type: types.CREATE_organization_SUCCESS,
    payload: org,
  };
};
export const createOrganizationFailAction = (error) => {
  return {
    type: types.CREATE_organization_FAIL,
    payload: error,
  };
};

// VIEW organization
export const viewOrganizationAction = (organizations_uuid) => {
  return {
    type: types.VIEW_organization,
    payload: organizations_uuid,
  };
};
export const viewOrganizationSuccessAction = (org) => {
  return {
    type: types.VIEW_organization_SUCCESS,
    payload: org,
  };
};
export const viewOrganizationFailAction = (error) => {
  return {
    type: types.VIEW_organization_SUCCESS,
    payload: error,
  };
};

//VIEW organization SUBSCRIBER
export const viewOrganizationSubscriberAction = (organization_uuid) => {
  return {
    type: types.VIEW_organization_SUBSCRIBER,
    payload: organization_uuid,
  };
};
export const viewOrganizationSubscriberSuccessAction = (subscribers) => {
  return {
    type: types.VIEW_organization_SUBSCRIBER_SUCCESS,
    payload: subscribers,
  };
};
export const viewOrganizationSubscriberFailAction = (error) => {
  return {
    type: types.VIEW_organization_SUBSCRIBER_FAIL,
    payload: error,
  };
};

// EDIT organization
export const editOrganizationAction = (organization_uuid, formValue) => {
  return {
    type: types.EDIT_organization,
    payload: { formValue, organization_uuid },
  };
};
export const editOrganizationSuccessAction = (values) => {
  return {
    type: types.EDIT_organization_SUCCESS,
    payload: values,
  };
};
export const editOrganizationFailAction = (error) => {
  return {
    type: types.EDIT_organization_FAIL,
    payload: error,
  };
};

// DELETE organization
export const deleteOrganizationAction = (organizations_uuid) => {
  return {
    type: types.DELETE_organization,
    payload: organizations_uuid,
  };
};
export const deleteOrganizationSuccessAction = () => {
  return {
    type: types.DELETE_organization_SUCCESS,
  };
};
export const deleteOrganizationFailAction = (error) => {
  return {
    type: types.DELETE_organization_FAIL,
    payload: error,
  };
};
