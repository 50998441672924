import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listOrgMessagesAction } from '../../store/actions/orgMessageActions';
import { Link } from 'react-router-dom';
import dateFormatWithTime from '../../utils/dateFormat';
import getMessageStatus from '../../components/status/message';
import { useTranslation } from 'react-i18next'

function OrgMessageTable({ organization_uuid }) {

  const { t } = useTranslation();

  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    organization_uuid,
  };

  const menuFilter = [
    {
      key: '1',
      value: t('table.filter.sent_messages'),
    },
    {
      key: '2',
      value: t('table.filter.draft_messages'),
    },
    {
      key: '3',
      value: t('table.filter.scheduled_messages'),
    },
  ];

  const { orgMessages } = useSelector((state) => ({
    orgMessages: state.orgMessages,
  }));

  const { orgMessagesListLoading, orgMessagesListError, orgMessagesList } =
    orgMessages;

  const { Column } = Table;

  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="status"
      dispatchAction={listOrgMessagesAction}
      data={orgMessagesList}
      loading={orgMessagesListLoading}
      error={orgMessagesListError}
    >
      <Column
        title={t('table.thead.msg_heading')}
        dataIndex="title"
        key="title"
        align="left"
        render={(text, record) => record?.title}
        ellipsis={true}
        width="100px"
      />
      <Column
        title={t('table.thead.msg_org_name')}
        dataIndex="organization"
        key="organization"
        render={(text, record) => record?.organization}
      />
      <Column
        title={t('table.thead.msg_timestamp')}
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        defaultSortOrder="descend"
        render={(text, record) => dateFormatWithTime(record?.created_at)}
      />
      <Column
        title={t('table.thead.msg_views')}
        dataIndex="views"
        key="views"
        sorter={true}
        render={(text, record) => record?.views}
      />
      <Column
        title={t('table.thead.msg_language')}
        dataIndex="language"
        key="language"
        render={(text, record) => record?.language || '-'}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(text, record) => getMessageStatus(record?.status)}
      />
      <Column
        className="text-sm px-2 py-3"
        title={t('table.thead.actions')}
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          record?.status === 1 ?
            (
              <Link
                className="text-blue-anak"
                to={`/messages/${record.uuid}`}
              >
                {t('table.thead.actions_btn')}
              </Link>
            ) :
            (
              <Link
                className="text-red-anak"
                style={{ color: '#FF7600' }}
                to={`/messages/edit/${record?.uuid}`}
              >
                {t('table.thead.actions_edit_btn')}
              </Link>
            )
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default OrgMessageTable;
