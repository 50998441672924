import React from 'react';

function BoxedShadow({ children, className }) {
  return (
    <div
      className={`bg-white rounded-md shadow-lg border border-grey-200 px-4 py-6 ${className}`}
    >
      {children}
    </div>
  );
}

export default BoxedShadow;
