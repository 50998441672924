const translationEnglish = {
  left_sidebar: {
    dashboard: 'Dashboard',
    org_message: 'Org Message',
    org_details: 'Org Details',
    org_user: 'Org Users',
    customer_list: 'Customers'
  },
  navbar: {
    user_dropdown: {
      logout: 'Logout',
      editProfile: 'Edit Profile',
    },
  },
  profileEdit: {
    section_title: 'Personal Info',
    profile_pic: 'Profile Picture',
    upload_photo: 'Upload Photo',
    delete_photo: 'Delete Photo',
    save_photo: 'Save Photo',
    full_name: 'Full Name',
    email: 'Email',
    email_helpText: 'To change your email id. please',
    email_helpText_link: 'send request to Super Admin',
    reset_password_label: 'Reset Password',
    password_placeholder: 'Enter Current Password',
    password_helpText1: 'Forgot your current password? Please',
    password_helpText2: 'Click Here',
    password_helpText3: 'to get the password reset link',
    new_password_placeholder: 'Enter New Password',
    confirm_password_placeholder: 'Confirm Password',
    btn_save: 'Save Changes',
    btn_cancel: 'Cancel',
    error_password: 'Please input your current password!',
    error_new_password: 'Please input new password!',
    error_password_match: 'New password does not match',
  },
  dashboard: {
    orgDetails: {
      category: 'Category',
      status: 'Status'
    },
    customer_details: {
      section_title: 'All Customer Details',
      graph_title_subscribed: 'Subscribed Customer by Month',
      graph_title_unsubscribed: 'Unsubscribed Customer by Month',
      subscribed_customers: 'Subscribed Customers',
      unsubscribed_customers: 'Unsubscribed Customers',
      total_customers: 'Total Customers',
    },
    message_details: {
      section_title: 'Message Details',
      graph_title_all: 'All Messages by Month',
      graph_title_published: 'Published Messages by Month',
      graph_title_scheduled: 'Scheduled Messages by Month',
      graph_title_draft: 'Draft Messages by Month',
      all_msgs: 'All Messages',
      published_msgs: 'Published Messages',
      scheduled_msgs: 'Scheduled Messages',
      draft_msgs: 'Draft Messages',
    },
  },
  customer_list: {
    section_title: 'Customer List',
  },
  customer_details: {
    section_title: 'Customer Details',
  },
  system_users: {
    section_title: 'System Users',
    add_system_user_drawer: {
      button_text: 'Add New System User',
      drawer_title: 'Add New System User',
      form_name: {
        label: 'Full Name',
        placeholder: 'Enter name',
        error: "Please input user's name",
      },
      form_email: {
        label: 'Email Address',
        placeholder: 'Enter email address',
        error: 'Please input valid email.',
      },
      form_role: {
        label: 'User Role',
        placeholder: 'Select user role',
        error: 'Select user role',
      },
      submit_text: 'Add New System User',
    },
    add_modal: {
      button_text: 'Add New System User',
      title: 'Add New System User',
      name_label: 'Full Name',
      name_placeholder: 'Enter name',
      name_error: "Please input user's name",
      email_label: 'Email Address',
      email_placeholder: 'Enter email address',
      email_error: 'Please input valid email.',
      role_label: 'User Role',
      role_placeholder: 'Select user role',
      role_error: 'Select user role',
      confirm_text: 'Add New Org User',
    },
  },
  system_user_details: {
    section_title: 'System User Details',
    profile_header: {
      name: 'Name',
      email: 'Email ID',
      role: 'Role',
      status: 'Status',
      created_at: 'Created At',
      updated_at: 'Updated At',
      created_by: 'Created By',
      last_login: 'Last Login',
      edit_system_user_drawer: {
        button_text: 'Edit Profile',
        drawer_title: 'Edit User',
        form_name_label: 'Full Name',
        form_email_label: 'Email Address',
        form_role_label: 'Role',
        drawer_submit_text: 'Update System User',
      },
      reset_password_popup: {
        title: 'Reset Password',
        description:
          'Are you sure you want to reset the password of this user?',
        form_email_label: 'Email Address',
        form_email_placeholder: 'Enter email address',
        form_email_message: 'Please input your email!',
        confirm_text: 'Reset Password',
        cancel_text: 'Cancel',
      },
    },
    permission_information: {
      permission_1: 'Permission name 1',
      permission_1_detail:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

      permission_2: 'Permission name 2',
      permission_2_detail:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

      permission_3: 'Permission name 3',
      permission_3_detail:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

      permission_4: 'Permission name 4',
      permission_4_detail:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
  },
  roles_and_permission: {
    section_title: 'Roles and Permission',
    role: {
      widget_title: 'Role',
      roles_dropdown: {
        label: 'Select Role',
        user: 'User',
        moderator: 'Moderator',
        admin: 'Admin',
        super_admin: 'Super Admin',
      },
      description:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    permissions: {
      permission_1: 'Permission name 1',
      permission_1_detail:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

      permission_2: 'Permission name 2',
      permission_2_detail:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

      permission_3: 'Permission name 3',
      permission_3_detail:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",

      permission_4: 'Permission name 4',
      permission_4_detail:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
  },
  alerts: {
    table_error: 'Failed to load table. Retry after sometime.',
    passwordUpdated: 'Password Updated',
  },
  org_category: {
    section_title: 'organization Category',
    create_modal: {
      button_text: 'Create New Category',
      title: 'Add New Category',
      form_label: 'Category Name',
      form_placeholder: 'Enter category name',
      confirm_text: 'Save',
    },
    details: {
      category_name: 'Category Name',
      created_on: 'Created On',
      total: 'No. of organization',
    },
    edit_modal: {
      button_text: 'Edit Category',
      title: 'Edit Category',
      form_label: 'Category Name',
      form_placeholder: 'Enter category name',
      confirm_text: 'Save',
    },
    delete_modal: {
      button_text: 'Delete',
      title: 'Delete Category',
      description: 'Are you sure you want to delete this category',
      confirm_text: 'Delete',
      cancel_text: 'Cancel',
    },
  },
  org_details: {
    section_title: 'Organization Details',
    status: 'Status',
    category: 'Category',
    tab_description: 'Description',
    tab_Users: 'Users',
    tab_message: 'Messages',
    tab_contact: 'Contact Details',
    selectLanguage: 'Select Language',
    edit_modal: {
      button_text: 'Edit Organization',
      title: 'Edit Organization',
      // ADD MORE FROM add_organization
    },
    delete_modal: {
      button_text: 'Delete Organization',
      title: 'Delete Organization',
      description: 'Are you sure you want to delete this organization',
      confirm_text: 'Delete',
      cancel_text: 'Cancel',
    },
  },
  organization: {
    section_title: 'Organizations',
  },
  add_organization: {
    button_text: 'Add New organization',
    title_add: 'Add New organization',
    title_edit: 'Edit organization',
    name_label: 'organization Name',
    name_placeholder: 'Enter organization Name',
    category_label: 'organization Category',
    category_placeholder: 'Select organization Category',
    logo_label: 'organization Logo',
    logo_placeholder: 'No file selected',
    logo_cta: 'Choose File',
    description_label: 'Description',
    description_placeholder: 'Type message contents',
    add_language: 'Add New Language',
    remove_language: 'Remove Language',
    email_label: 'Contact Email',
    email_placeholder: 'Enter contact email',
    phone_label: 'Contact Phone',
    phone_placeholder: 'Enter contact phone',
    url_label: 'Website URL',
    url_placeholder: 'Enter website url',
    confirm_text: 'Save',
  },
  org_message: {
    section_title: 'Organization Message',
    create: {
      button_text: 'Create New Message',
      title: 'Create New Message',
      org_label: 'Organization',
      org_placeholder: 'Select Organization',
      recipients_label: 'Recipients',
      recipients_placeholder: 'Add Recipients',
      msg_title_label: 'Message Title',
      msg_title_placeholder: 'Enter Message Title',
      msg_content_label: 'Message Content',
      msg_content_placeholder: 'Type Message Content',
      image_label: 'Attach Image',
      image_placeholder: 'No file selected',
      image_cta: 'Choose File',
      video_label: 'Attach Video',
      video_placeholder: 'Enter video link',
      file_label: 'Attach File',
      file_placeholder: 'Paste file link/url',
      file_cta: 'Choose File',
      send_text: 'Send',
      draft_text: 'Save in Draft',
      schedule_text: 'Schedule',
    },
    details: {
      title: 'Organization Message Details',
      name_label: 'Organization Name',
      date_label: 'Sent Date & Time',
      language_label: 'Language',
      status_label: 'Status',
      views_label: 'User Views',
      heading_label: 'Message Heading',
      content_label: 'Message Content',
      video_label: 'Message Video',
      cta_label: 'Call to Action',
    },
  },
  org_user: {
    section_title: 'Organization User',
    add_modal: {
      button_text: 'Add New Org User',
      title: 'Add New Org User',
      name_label: 'Full Name',
      name_placeholder: 'Enter name',
      name_error: 'Please input name',
      email_label: 'Email ID',
      email_placeholder: 'Enter email id',
      email_error: 'Please input valid email.',
      role_label: 'User Role',
      role_placeholder: 'Select user role',
      role_error: 'Select user role',
      confirm_text: 'Add New Org User',
    },
  },
  org_user_detail: {
    section_title: 'Organization User Details',
    name_label: 'Name',
    email_label: 'Email',
    role_label: 'Role',
    status_label: 'Status',
    created_label: 'Created At',
    updated_label: 'Updated At',
    created_by_label: 'Created By',
    last_login_label: 'Last Login',
    org_name_label: 'Organization Name',
    permissions_title: 'Permission Information',
    edit_modal: {
      button_text: 'Edit Profile',
      title: 'Edit User',
      name_label: 'Full Name',
      name_placeholder: 'Enter name',
      name_error: 'Please input name',
      email_label: 'Email ID',
      email_placeholder: 'Enter email id',
      email_error: 'Please input valid email.',
      org_label: 'organization',
      org_placeholder: 'Enter organization',
      org_error: 'Please provide organization.',
      role_label: 'User Role',
      role_placeholder: 'Select user role',
      role_error: 'Select user role',
      confirm_text: 'Update System User',
    },
    password_modal: {
      button_text: 'Reset Password',
      title: 'Reset Password',
      description: 'Are you sure you want to reset the password of this user?',
      email_label: 'Email ID',
      email_placeholder: 'Enter email id',
      email_error: 'Please input valid email.',
      confirm_text: 'Reset Password',
      cancel_text: 'Cancel',
      password_not_match: "Password doesn't match."
    },
    delete_modal: {
      title: 'Delete User',
      description: 'Are you sure you want to delete this user from the system?',
    },
    status_modal: {
      title: 'Change User Status',
      active: 'Active',
      suspend: 'Suspend',
      delete: 'Delete',
    }
  },
  table: {
    search_placeholder: 'Search',

    pagination: {
      filter_show: 'Show',
      filter_entries: 'entries',
      index_text_showing: 'Showing',
      index_text_to: 'to',
      index_text_of: 'of',
      index_text_entries: 'Entries',
    },
    thead: {
      serial_number: 'Sl. No',
      user_name: 'Name',
      user_mail: 'Email',
      user_phone: 'Phone No.',
      user_registered_on: 'Registered On',
      user_created_at: 'Created At',
      user_updated_at: 'Updated At',
      user_created_by: 'Created By',
      user_last_login: 'Last Login',
      actions: 'Actions',
      subscribed_on: 'Subscribed On',
      unsubscribed_on: 'Unsubscribed On',
      msg_heading: 'Message Heading',
      msg_org_name: 'Organization Name',
      msg_timestamp: 'Date & Time',
      msg_views: 'Users View',
      msg_language: 'Language',
      msg_status_subscribed: 'Subscribed',
      msg_status_unsubscribed: 'Unsubscribed',
      status: 'Status',
      actions_btn: 'View Details',
      actions_edit_btn: 'Edit Message',
      subscriptionStatus: 'Subscription Status',
      subscribed: 'Subscribed',
      unsubscribed: 'Unsubscribed',
    },
    filter: {
      sent_messages: 'Sent Messages',
      draft_messages: 'Draft Messages',
      scheduled_messages: 'Scheduled Messages',
      filter_label: 'Filter',
      filter_date: 'Filter By Date',
      clear_filter: 'Clear Filter'
    },
    status: {
      active: 'Active',
      inactive: 'Inactive',
    }
  },
  form: {
    loading_label: 'Loading...',
    name_label: 'Full Name',
    name_placeholder: 'Enter Name',
    name_error: 'Please input name',
    email_Label: 'Email ID',
    email_placeholder: 'Enter Email Address',
    email_required: 'Please input email address',
    email_error: 'Please input valid email address',
    contact_email_label: 'Contact Email',
    contact_email_placeholder: 'Enter Contact Email',
    reset_text: 'Reset Password',
    save_text: 'Save',
    cancel_text: 'Cancel',
    password_label: 'Enter Password',
    password_placeholder: 'Enter New Password',
    password_not_match: 'Passwords do not match',
    contact_phone_label: 'Contact Phone',
    contact_phone_placeholder: 'Enter Contact Phone',
    contact_phone_error: 'Please input organisation phone!',
    org_name_label: 'Organization',
    org_name_placeholder: 'Select Organization',
    org_name_error: 'Please input organization name!',
    org_category_label: 'Organization Category',
    org_category_placeholder: 'Select Organization Category',
    org_category_error: 'Please Select Organization Category',
    org_category_load_error: 'Failed to load organization categories',
    org_website_label: 'Website URL',
    org_website_placeholder: 'Enter Website URL',
    org_website_error: 'Please input organisation website!',
    org_desc: 'Description',
    org_desc_placeholder: 'Type description content',
    add_language: 'Add New Language',
    remove_language: 'Remove Language',
    org_logo_label: 'Organisation Logo',
    msg_title_label: 'Message',
    msg_title_placeholder: 'Enter Message Title',
    msg_content_label: 'Message Content',
    msg_content_placeholder: 'Enter Message Content',
    org_label: 'Organization',
    org_placeholder: 'Select Organization',
    recipients_label: 'Recipients',
    recipients_placeholder: 'Add Recipients',
    image_label: 'Attach Image',
    image_placeholder: 'No file selected',
    image_cta: 'Choose File',
    video_label: 'Attach Video',
    video_placeholder: 'Enter video link',
    file_label: 'Attach File',
    file_placeholder: 'Paste file link/url',
    file_cta: 'Choose File',
    send_text: 'Send',
    draft_text: 'Save in Draft',
    schedule_text: 'Schedule',
    update_org_user: 'Update Organization User',
    role_label: 'Role',
    role_placeholder: 'Select Role',
    status_active: 'Active',
    status_inactive: 'Inactive',
    status_suspend: 'Suspend',
    status_delete: "Delete",
  }
};

export default translationEnglish;
