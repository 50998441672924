import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SectionTitle from '../../components/dashboard/section-title';
import Breadcrumb from '../../components/breadcrumbs';
import { BiPlusCircle } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Drawer, Button, Spin } from 'antd';
import OrgUsersTable from './OrgUsersTable';
import { createOrgUserAction } from '../../store/actions/orgUsersAction';
import { useTranslation } from 'react-i18next';
import CheckRole from '../../utils/checkRole';
import { listOrganizationAction } from '../../store/actions/organizationActions';

function OrgUsers() {
  const dispatch = useDispatch();
  const { orgUsers, organization, auth } = useSelector(
    (state) => ({
      orgUsers: state.orgUsers,
      organization: state.organization,
      auth: state.auth
    }),
    shallowEqual
  );

  const { currentUser } = auth;
  const { createUserLoading } = orgUsers;
  const { orgList, orgListLoading, orgListError } = organization;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useTranslation();
  let title = t('org_user.section_title');
  let breadcrumbsItem = [
    // { home: false, title: t('left_sidebar.dashboard'), url: '/', last: false },
    { title, url: '/', last: true },
  ];
  const { Option } = Select;
  const addSystemUserHandler = (values) => {
    dispatch(createOrgUserAction(values));
    setIsDrawerOpen(false);
  };
  return (
    <>
      <SectionTitle
        title={title}
        right={<Breadcrumb items={breadcrumbsItem} />}
      />
      <CheckRole>
        <div className="flex lg:flex-row-reverse mb-6">
          <Button
            type="success"
            size="large"
            className="flex items-center"
            onClick={() => setIsDrawerOpen(true)}
            disabled={createUserLoading}
            icon={
              createUserLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      size={22}
                      className="mr-2 mb-1 text-blue-anak"
                      spin
                    />
                  }
                />
              ) : (
                <BiPlusCircle size={24} className="mr-2" />
              )
            }
          >
            {t('org_user.add_modal.button_text')}
          </Button>
          <Drawer
            title={t('org_user.add_modal.title')}
            placement="right"
            onClose={() => setIsDrawerOpen(false)}
            visible={isDrawerOpen}
            headerStyle={{
              fontWeight: 'bold',
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Form
              layout="vertical"
              onFinish={addSystemUserHandler}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label={t('system_users.add_modal.name_label')}
                className="mb-6"
                rules={[
                  {
                    required: true,
                    message: t('system_users.add_modal.name_error'),
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder={t('system_users.add_modal.name_placeholder')}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label={t('system_users.add_modal.email_label')}
                className="mb-6"
                rules={[
                  {
                    required: true,
                    message: t('system_users.add_modal.email_error'),
                  },
                ]}
              >
                <Input
                  type="email"
                  size="large"
                  placeholder={t('system_users.add_modal.email_placeholder')}
                />
              </Form.Item>
              <Form.Item
                name="role"
                label={t('system_users.add_modal.role_label')}
                className="mb-6"
                rules={[
                  {
                    required: true,
                    message: t('system_users.add_modal.role_error'),
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder={t('system_users.add_modal.role_placeholder')}
                >
                  <Option key="2">Moderator</Option>
                  <Option key="3">Compliance Admin</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="mb-6"
                name="organization"
                label={t('org_user_detail.edit_modal.org_label')}
                rules={[
                  {
                    required: true,
                    message: 'Please input organization category!',
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder={t('org_user_detail.edit_modal.org_placeholder')}
                  onClick={
                    !orgList?.count > 0
                      ? () => dispatch(listOrganizationAction())
                      : null
                  }
                >
                  {!orgListLoading && orgList?.count > 0
                    ? orgList?.results?.map((item) => (
                      <Option key={item?.uuid} value={item?.uuid}>
                        {item?.name}
                      </Option>
                    ))
                    : 'Loading...'}
                  {orgListError && 'Failed to load organizations.'}
                </Select>
              </Form.Item>
              <Form.Item className="mb-6">
                <Button
                  type="success"
                  htmlType="submit"
                  className="w-full"
                  size="large"
                  block
                >
                  {t('system_users.add_modal.confirm_text')}
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        </div>
      </CheckRole>
      <Card bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <OrgUsersTable organization_uuid={currentUser?.organization} />
      </Card>
    </>
  );
}

export default OrgUsers;
