import React, { useState } from 'react';
import { Form, Input, Button, Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import { editOrganizationAction } from '../../store/actions/organizationActions';
import { RiMessage2Fill } from 'react-icons/ri';

function OrgWelcomeMessage() {
	// const { organization_uuid } = useParams();
	const dispatch = useDispatch();
	const [editWelcomeMessageForm] = Form.useForm();
	const [addMessageFormVisible, setAddMessageFormVisible] = useState(false);
	const { currentOrg } = useSelector((state) => state.organization);

	const messageInitialValues = {
		title: currentOrg?.welcome_message?.title,
		body: currentOrg?.welcome_message?.body,
	};

	const organization_uuid = currentOrg?.uuid;

	const isMessageEmpty = Object.keys(currentOrg?.welcome_message).length === 0;
	// console.log(Object.keys(currentOrg?.welcome_message)?.length);

	console.log(isMessageEmpty);

	const orgWelcomeMessageFormHandler = (values) => {
		dispatch(
			editOrganizationAction(organization_uuid, { welcome_message: values })
		);
		setAddMessageFormVisible(false)
		editWelcomeMessageForm.resetFields();
	};

	console.log(organization_uuid);

	return (
		<div className="">
			{isMessageEmpty ? (
				<div className="text-grey-500 flex w-full flex-col items-center mb-8 py-10">
					<div className="pb-6">
						<RiMessage2Fill className="text-6xl text-gray-100 opacity-25" />
					</div>
					<div className="pb-2 text-center lg:w-2/5 mx-auto">
						<div className="text-xl pb-3 font-bold text-blue-anak ">
							No welcome message!
						</div>
						<div className="text-grey-600">
							Looks like there are no welcome message created right now, <br /> please
							click the below button to create new one
						</div>
					</div>
					<Button
						type="success"
						size="large"
						className="my-4 lg:mb-0"
						onClick={() => setAddMessageFormVisible(true)}
					>
						Create A Welcome Message
					</Button>
				</div>
			) : (
				<div className="py-4">
					<div className="border-b border-grey-100 pb-2 mb-2 text-grey-700">
						Message Title
					</div>
					<div className="text-base font-bold pb-6">
						{currentOrg?.welcome_message?.title}
					</div>
					<div className="border-b border-grey-100 pb-2 mb-2 text-grey-700">
						Message Body
					</div>
					<div className="text-base pb-6">
						{currentOrg?.welcome_message?.body}
					</div>
					<Button
						type="success"
						size="large"
						onClick={() => setAddMessageFormVisible(true)}
					>
						Edit Welcome Message
					</Button>
				</div>
			)}
			<Drawer
				title="Edit Welcome Message"
				placement="right"
				onClose={() => {
					editWelcomeMessageForm.resetFields();
					setAddMessageFormVisible(false);
				}}
				visible={addMessageFormVisible}
				headerStyle={{ fontWeight: 'bold', paddingTop: 20, paddingBottom: 20 }}
			>
				<Form
					name="orgWelcomeMessageForm"
					autoComplete="off"
					layout="vertical"
					onFinish={orgWelcomeMessageFormHandler}
					scrollToFirstError
					form={editWelcomeMessageForm}
					initialValues={messageInitialValues}
				>
					<Form.Item
						name="title"
						label="Message Title"
						className="mb-6"
						rules={[
							{
								required: true,
								message: 'Please provide message title!',
							},
						]}
					>
						<Input size="large" placeholder="Message Title" />
					</Form.Item>
					<Form.Item
						name="body"
						label="Message Body"
						className="mb-10"
						tooltip="Message body should be less than 500 characters!"
						rules={[
							{
								required: true,
								message: 'Please provide message body!',
							},
							{
								max: 500,
								message: 'Character limit exceeded!',
							},
						]}
					>
						<Input.TextArea
							rows={6}
							maxLength={500}
							allowClear
							showCount
							placeholder="Message Body"
						/>
					</Form.Item>
					<div className="flex mt-6">
						<Button
							type="success"
							size="large"
							className="w-full mb-4 lg:mb-0"
							htmlType="submit"
						>
							{`${isMessageEmpty ? 'Add' : 'Update'} Welcome Message`}
						</Button>
					</div>
				</Form>
			</Drawer>
		</div>
	);
}

export default OrgWelcomeMessage;