import { put, all, takeLatest } from 'redux-saga/effects';
import * as types from '../store/actionTypes/org-messages';
import { notification } from 'antd';
import {
  listOrgMessageService,
  getOrgMessageService,
  createOrgMessageService,
  editOrgMessageService,
} from '../services/orgMessagesService';
import {
  createOrgMessageSuccessAction,
  createOrgMessageFailAction,
  listOrgMessagesFailedAction,
  listOrgMessagesSuccessAction,
  viewOrgMessageSuccessAction,
  viewOrgMessageFailAction,
  editOrgMessageSuccessAction,
  editOrgMessageFailAction,
} from '../store/actions/orgMessageActions';
import history from '../utils/history';

//Worker
export function* listOrgMessage({ payload }) {
  try {
    const list = yield listOrgMessageService(payload);
    yield put(listOrgMessagesSuccessAction(list.data.data));
  } catch (error) {
    yield put(listOrgMessagesFailedAction(error));
  }
}
export function* getOrgMessageDetails({ payload: OrgCategoryUuid }) {
  try {
    const details = yield getOrgMessageService(OrgCategoryUuid);
    yield put(viewOrgMessageSuccessAction(details.data.data));
  } catch (error) {
    yield put(viewOrgMessageFailAction(error));
  }
}
export function* createOrgMessage({ payload: category_name }) {
  try {
    const create = yield createOrgMessageService(category_name);
    yield put(createOrgMessageSuccessAction(create));
    notification['success']({
      message: `Message Created Successfully.`,
      // description: 'Success description',
    });
    history.push('/messages/');
  } catch (error) {
    yield put(createOrgMessageFailAction(error));
    notification['error']({
      message: 'Failed to create message.',
      description: 'Please retry or contact administration.',
    });
  }
}

export function* editOrgMessage({ payload }) {
  try {
    const create = yield editOrgMessageService(payload);
    yield put(editOrgMessageSuccessAction(create));
    notification['success']({
      message: `Message updated.`,
      // description: 'Success description',
    });
    history.push('/organisations/messages');
  } catch (error) {
    yield put(editOrgMessageFailAction(error));
    notification['error']({
      message: 'Failed to update message.',
      description: 'Please retry or contact administration.',
    });
  }
}

//Watcher
export function* onListOrgMessageRequest() {
  yield takeLatest(types.LIST_ORG_MESSAGES, listOrgMessage);
}
export function* onGetOrgMessageDetails() {
  yield takeLatest(types.VIEW_ORG_MESSAGE, getOrgMessageDetails);
}
export function* onCreateOrgMessage() {
  yield takeLatest(types.CREATE_ORG_MESSAGE, createOrgMessage);
}

export function* onEditOrgMessage() {
  yield takeLatest(types.EDIT_ORG_MESSAGE, editOrgMessage);
}

export function* orgMessageSaga() {
  yield all([
    onListOrgMessageRequest(),
    onGetOrgMessageDetails(),
    onCreateOrgMessage(),
    onEditOrgMessage(),
  ]);
}
