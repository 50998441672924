import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { customerSaga } from './customerSaga';
import { messagesSaga } from './messagesSaga';
import { userProfile } from './userProfileSaga';
import { imageUploadSaga } from './uploadSaga';
import { organizationSaga } from './organizationSaga';
import { orgMessageSaga } from './orgMessageSaga';
import { orgUsersSaga } from './orgUserSaga';
import { orgCategorySaga } from './orgCategorySaga';
import { dashboardSaga } from './dashboardSaga';

function* rootSaga() {
  yield all([
    authSaga(),
    dashboardSaga(),
    customerSaga(),
    messagesSaga(),
    userProfile(),
    imageUploadSaga(),
    organizationSaga(),
    orgMessageSaga(),
    orgUsersSaga(),
    orgCategorySaga(),
  ]);
}

export default rootSaga;
