import * as types from '../actionTypes/org-users';

const initialState = {
  usersList: null,
  userListLoading: false,
  userListError: null,
  createUserLoading: false,
  createUserError: null,
  editUserLoading: false,
  editUserSuccess: false,
  editUserError: null,
  currentUserLoading: false,
  currentUserSuccess: false,
  currentUserError: null,
  currentUser: null,
  changeUserStatusLoading: false,
  changeUserStatusError: null,
  resetPasswordLoading: false,
  resetPasswordError: null,
  resetPasswordSuccess: null,
};

const orgUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_ORG_USERS:
      return {
        ...state,
        userListLoading: true,
      };
    case types.LIST_ORG_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        userListLoading: false,
      };
    case types.LIST_ORG_USERS_FAILED:
      return {
        ...state,
        userListLoading: false,
        userListError: action.payload,
      };
    case types.CREATE_ORG_USER:
      return {
        ...state,
        createUserLoading: true,
      };
    case types.CREATE_ORG_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
      };

    case types.CREATE_ORG_USER_FAIL:
      return {
        ...state,
        createUserLoading: false,
        createUserError: action.payload.message,
      };
    case types.EDIT_ORG_USER:
      return {
        ...state,
        editUserLoading: true,
        editUserSuccess: false,
        editUserError: null,
      };
    case types.EDIT_ORG_USER_SUCCESS:
      return {
        ...state,
        editUserLoading: false,
        editUserSuccess: true,
        editUserError: null,
        currentUser: action.payload,
      };
    case types.EDIT_ORG_USER_FAIL:
      return {
        ...state,
        editUserLoading: false,
        editUserSuccess: false,
        editUserError: action.payload,
      };
    case types.VIEW_ORG_USER:
      return {
        ...state,
        currentUserLoading: true,
        currentUserSuccess: false,
        currentUserError: null,
        currentUser: null,
      };
    case types.VIEW_ORG_USER_SUCCESS:
      return {
        ...state,
        currentUserLoading: false,
        currentUserSuccess: true,
        currentUserError: null,
        currentUser: action.payload,
      };
    case types.VIEW_ORG_USER_FAIL:
      return {
        ...state,
        currentUserLoading: false,
        currentUserSuccess: false,
        currentUserError: action.payload,
        currentUser: null,
      };
    case types.CHANGE_ORG_USER_STATUS:
      return {
        ...state,
        changeUserStatusLoading: true,
        changeUserStatusError: null,
      };
    case types.CHANGE_ORG_USER_STATUS_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        changeUserStatusLoading: false,
        changeUserStatusError: null,
      };
    case types.CHANGE_ORG_USER_STATUS_FAIL:
      return {
        ...state,
        changeUserStatusLoading: false,
        changeUserStatusError: action.payload,
      };
    case types.UPDATE_ORG_USER_PASSWORD:
      return {
        ...state,
        resetPasswordError: null,
        resetPasswordSuccess: null,
        resetPasswordLoading: true,
      };
    case types.UPDATE_ORG_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordError: null,
        resetPasswordSuccess: action.payload,
        resetPasswordLoading: false,
      };
    case types.UPDATE_ORG_USER_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordError: action.payload,
        resetPasswordSuccess: null,
        resetPasswordLoading: false,
      };

    default:
      return state;
  }
};

export default orgUsersReducer;
