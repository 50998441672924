export const categoryDetails = [
  {
    id: 1,
    name: 'Singapore National Employers Federation',
    url: '/logos/snef-logo.png',
    createdDate: '18th Oct, 2021 - 03:30pm',
    category: 'Social Support',
  },
  {
    id: 2,
    name: 'Migrant Workers’ Centre',
    url: '/logos/mwc_logo.png',
    createdDate: '18th Oct, 2021 - 03:30pm',
    category: 'Category Name',
  },
  {
    id: 3,
    name: 'Health Serve',
    url: '/logos/healthserve_log.jpg',
    createdDate: '19th Oct, 2021 - 03:30pm',
    category: 'Social Support',
  },
  {
    id: 4,
    name: 'National Volunteer & Philanthropy Centre',
    url: '/logos/nvpc-logo.png',
    createdDate: '24th Oct, 2021 - 03:30pm',
    category: 'Category Name',
  },
  {
    id: 5,
    name: 'Transient Workers Count Too',
    url: '/logos/twc2-logo.png',
    createdDate: '11th Oct, 2021 - 03:30pm',
    category: 'Social Support',
  },
  {
    id: 6,
    name: 'SG Accident Help Centre',
    url: '/logos/sg_logo.webp',
    createdDate: '28th Oct, 2021 - 03:30pm',
    category: 'Category Name',
  },
  {
    id: 1,
    name: 'Singapore National Employers Federation',
    url: '/logos/snef-logo.png',
    createdDate: '6th Oct, 2021 - 03:30pm',
    category: 'Social Support',
  },
  {
    id: 2,
    name: 'Migrant Workers’ Centre',
    url: '/logos/mwc_logo.png',
    createdDate: '8th Oct, 2021 - 03:30pm',
    category: 'Category Name',
  },
  {
    id: 3,
    name: 'Health Serve',
    url: '/logos/healthserve_log.jpg',
    createdDate: '10th Oct, 2021 - 03:30pm',
    category: 'Social Support',
  },
  {
    id: 4,
    name: 'National Volunteer & Philanthropy Centre',
    url: '/logos/nvpc-logo.png',
    createdDate: '7th Oct, 2021 - 03:30pm',
    category: 'Social Support',
  },
  {
    id: 5,
    name: 'Transient Workers Count Too',
    url: '/logos/snef-logo.png',
    createdDate: '5th Oct, 2021 - 03:30pm',
    category: 'Social Support',
  },
  {
    id: 6,
    name: 'SG Accident Help Centre',
    url: '/logos/sg_logo.webp',
    createdDate: '23th Oct, 2021 - 03:30pm',
    category: 'Social Support',
  },
];

export const orgEditData = {
  orgName:
    'Singapore National Employers Federation Responsible Employers Business',
  orgCategory: 'Social Support',
  orgLogo: '/logos/snef-logo.png',
  orgDescription: `<p>SNEF provides services to employers in these key areas:</p><p>1. Represent the key interests of employers in national tripartite committees, forums and national-level reviews</p><p>2. Provide expert consultancy and advice to corporate members by experienced consultants on the proper application of local labour laws, policies and tripartite guidelines</p><p>3. Update corporate members on the latest important developments in labour, manpower and employment issues through briefings, industrial group meetings and other platforms</p>`,
  orgContactMail: 'snef@anak.com',
  orgContactPhone: '612345678',
  orgUrl: 'https://www.snef-agency.com',
};
export const reactQuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const reactQuillFormats = [
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'link',
  'image',
  'video',
];
