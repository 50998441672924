import moment from 'moment';

export function dateFormat(date) {
  if (moment(date).isValid()) {
    return moment(date).format('Do MMM, YYYY');
  } else {
    return '-';
  }
};

export default dateFormat;

export function dateFormatWithTime(date) {
  if (moment(date).isValid()) {
    return moment(date).format('Do MMM, YYYY:HH:MM');
  } else {
    return '-';
  }
};


