import { put, all, takeEvery, delay } from 'redux-saga/effects';
import * as types from '../store/actionTypes';
import {
  listMessagesSuccessAction,
  listMessagesFailedAction,
} from '../store/actions/messagesActions';
import { listMessagesService } from '../services/messagesService';

// import { isEmpty } from '../utils/generic';

//Worker
export function* listMessages() {
  try {
    yield delay(1000)
    const list = yield listMessagesService();
    yield put(listMessagesSuccessAction(list));
  } catch (error) {
    yield put(listMessagesFailedAction(error));
  }
}


//Watcher
export function* onListRequest() {
  yield takeEvery(types.LIST_MESSAGES_REQUEST, listMessages);
}

export function* messagesSaga() {
  yield all([
    onListRequest(),
  ]);
}