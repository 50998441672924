import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import TableUniversal from '../../components/table';
import { listOrgUsersAction } from '../../store/actions/orgUsersAction';
import { Link } from 'react-router-dom';
import dateFormatWithTime from '../../utils/dateFormat';
import { replaceUnderscore } from '../../utils/string-helpers.js'
// import { BiUser } from 'react-icons/bi'
// import { createdByFormat } from '../../utils/roleFormat';
// import { capitalizeEachWord } from '../../utils/string-helpers';

function OrgUsersTable({ organization_uuid }) {
  const initialTableParams = {
    page: 1,
    search: '',
    status: '',
    role: '',
    ordering: '',
    page_size: 10,
    created_at_after: '',
    created_at_before: '',
    organization_uuid,
  };

  const menuFilter = [
    {
      key: '2',
      value: 'Moderator'
    },
    {
      key: '3',
      value: 'Org Admin',
    },
    {
      key: '4',
      value: 'Org User',
    },
  ];

  const { orgUsers } = useSelector(
    (state) => ({
      orgUsers: state.orgUsers,
    }));

  const { usersList, userListLoading, userListError } = orgUsers;

  const { Column } = Table;

  return (
    <TableUniversal
      initialTableParams={initialTableParams}
      menuFilter={menuFilter}
      menuFilterBy="role"
      dispatchAction={listOrgUsersAction}
      data={usersList}
      loading={userListLoading}
      error={userListError}
    >
      {/* <Column
        title="Name"
        dataIndex="name"
        key="name"
        sorter={true}
        align="left"
        render={(text, record) => (
          <div className="flex items-center pl-1">
            {
              record?.profile_image ? (
                <span
                  className="flex justify-center items-center md:h-10 w-10 hp-2 border-2 shadow border-white rounded bg-white bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${record?.profile_image})`
                  }}
                />
              ) : (
                <Avatar
                  className=" flex justify-center items-center w-24 md:w-48 border-2 border-white font-bold shadow rounded text-sm"
                  style={{ verticalAlign: 'middle', backgroundColor: '#e9e9ea', color: '#323232', fontSize: "16px" }}
                  size={40}
                >
                  {record?.name.charAt(0, 2)}
                </Avatar>
              )
            }
            <div className="ml-3">
              <span className="block text-base font-bold">{record?.name}</span>
              <span className="block text-xs text-grey-600">{record?.user_detail?.email}</span>
            </div>
          </div>
        )}
      /> */}
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        align="left"
        render={(text, record) => record?.name}
      />
      <Column
        title="Email ID."
        dataIndex="email"
        key="email"
        align="left"
        render={(text, record) => record?.user_detail?.email}
      />
      <Column
        title="Role"
        dataIndex="role"
        key="role"
        render={(text, record) => replaceUnderscore(record?.user_detail?.role[0])}
      />
      <Column
        title="Created At"
        dataIndex="created_at"
        key="created_at"
        className="capitalize"
        sorter={true}
        render={(text, record) => dateFormatWithTime(record?.created_at)}
      />
      <Column
        title="Updated At"
        dataIndex="modified_at"
        key="modified_at"
        sorter={true}
        render={(text, record) => dateFormatWithTime(record?.modified_at)}
      />

      <Column
        title="Created By"
        dataIndex="created_by"
        key="created_by"
        sorter={true}
        render={(text, record) => record?.created_by}
      />
      <Column
        title="Last Login"
        dataIndex="last_login"
        key="last_login"
        render={(text, record) =>
          dateFormatWithTime(record?.last_login)
        }
      />
      <Column
        className="text-sm px-2 py-3"
        title="Actions"
        dataIndex="action"
        key="action"
        fixed="right"
        render={(text, record) => (
          <Link to={`/users/${record.uuid}`}>
            View Details
          </Link>
        )}
        width={120}
      />
    </TableUniversal>
  );
}
export default OrgUsersTable;
