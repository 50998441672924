import { isEmpty } from './generic';

export const capitalizeFirstLetter = (string) => {
  if (!isEmpty(string)) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return 'NA';
  }
};

export const replaceUnderscore = (string) => {
  if (!isEmpty(string)) {
    return string.toString().replace(/_/g, ' ');
  } else {
    return 'NA';
  }
};

export const capitalizeEachWord = (input) => {
  if (!isEmpty(input)) {
    return input
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  } else {
    return 'NA';
  }
};

export const camelCaseString = ({ string }) => {
  console.log(string);
  if (!isEmpty(string)) {
    return string
      .toLowerCase()
      .split('_')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  } else {
    return 'NA';
  }
};
