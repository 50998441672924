// Root Reducer
import { combineReducers } from 'redux';
import config from './config';
import leftSidebar from './left-sidebar';
import palettes from './palettes';
import navigation from './navigation';
import auth from './authReducer';
import customers from './customerReducer';
import userProfile from './userProfileReducer';
import uploadReducer from './uploadReducer';
import organization from './organizationReducer';
import orgMessages from './orgMessageReducer';
import orgUsers from './orgUsersReducer';
import orgCategoryReducer from './orgCategoryReducer';
import dashboard from './dashboardReducer';

const rootReducer = combineReducers({
  dashboard,
  navigation,
  config,
  leftSidebar,
  palettes,
  auth,
  customers,
  userProfile,
  uploadReducer,
  organization,
  orgMessages,
  orgUsers,
  orgCategoryReducer,
});

export default rootReducer;
